import React from "react";
import { EarthCard } from "../EarthCard/EarthCard";
import { EarthText } from "../EarthText/EarthText";
import { connect } from 'react-redux';
import { EarthProof } from "../EarthProof/EarthProof";
import proofIdentity from '../../assets/images/proof-identity.png';
import proofResidency from '../../assets/images/proof-residency.png';

const Profile = ({ user }) => (
  <EarthCard className="mb-4 pt-3">
    <div className='text-center'>
      <div className="mb-3 mx-auto">
        <img
          style={{ objectFit: 'cover', objectPosition: 'center' }}
          className="rounded-circle"
          src={user.avatar || require('../../assets/images/captainPlanet.jpg')}
          alt={user.earthName}
          height={150}
          width={150}
        />
      </div>
      <EarthText fontStyle='bold'>{user.earthName}</EarthText>
      <EarthText size='xsmall'>Impact Level: Captain Planet</EarthText>

      <div className='divider' />

      <EarthText fontStyle='bold'>Account Details</EarthText>
      <EarthText>Email:{user.email}</EarthText>
      <EarthText>Access:{user.role}</EarthText>
      <EarthText>Preferred Currency:{user.currency.toUpperCase()}</EarthText>
      <EarthText>Preferred Language:{user.language.toUpperCase()}</EarthText>

      <div className='divider' />

      <EarthCard>
        <EarthText size="large" className="mb-2 text-center ">
          Your account is authenticated
        </EarthText>

        <EarthProof
          title="Proof of Residency"
          isChecked={true}
          image={proofResidency}
          description="Utility Bill issued wihin 3 months"
        />

        <EarthProof
          isChecked={true}
          title="Proof of Identity"
          description="Passport scan or clear photo"
          image={proofIdentity}
        />

      </EarthCard>
    </div>
  </EarthCard>
);

const mapStateToProps = state => ({
  user: state.root.user
});

export const UserDetails = connect(mapStateToProps)(Profile);