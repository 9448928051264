import React from 'react';
import { Input as NativeInput } from 'reactstrap';
import { Text } from './Text';

export const Input = ({ onChange, value, label, ...rest }) => (
  <>
    <Text din dark bold size='small'>{label}</Text>
    <NativeInput
      onChange={onChange}
      value={value}
      {...rest}
    />
  </>
)