import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { EarthApp, EarthAuth, Lock, AppLoading } from 'containers';
import { Provider, connect } from 'react-redux';
import { store, setLoading } from 'store';
import { ToastContainer } from 'react-toastify';
import { BottomTabs, EarthHeader } from 'components';

import 'assets/fonts/DINRoundPro-Medi.ttf'
import 'assets/fonts/FontsFree-Net-ApercuMedium.ttf'
import "assets/css/app.css";
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss";
import 'react-toastify/dist/ReactToastify.css';
import "react-image-gallery/styles/css/image-gallery.css";
import './app.scss';

const history = createBrowserHistory();
const mapStatetoProps = ({ root: { loading, locked } }) => ({ appLoading: loading, locked: locked });
const mapDispatchToProps = dispatch => ({ setLoading: state => dispatch(setLoading(state)) });

const App = connect(mapStatetoProps, mapDispatchToProps)(
  props => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [initialLocation, setInitialLocation] = useState('');

    useEffect(() => {
      loggedIn && initialLocation && history.push(initialLocation)
    }, [loggedIn, initialLocation])

    const onLoadingComplete = (loggedIn, location) => {
      props.setLoading(false);
      setLoggedIn(loggedIn);
      setInitialLocation(location)
    };

    const { appLoading, locked } = props;

    if (appLoading) return <AppLoading onLoadingComplete={onLoadingComplete} />

    if (locked) return <Lock />
    return (
      <div className="app">
        <Router history={history}>
          {loggedIn && <EarthHeader {...props} history={history} />}
          {
            !loggedIn ?
              <Switch>
                <Route path="/login" component={EarthAuth} />
                <Redirect from="*" to="/login" />
              </Switch>
              :
              <EarthApp />
          }
          {loggedIn && <BottomTabs history={history} />}
        </Router>
        <ToastContainer position="bottom-center" />
      </div>
    )
  }
);

const WrappedApp = () => (
  <Provider store={store}>
    <App />
  </Provider>
)

ReactDOM.render(<WrappedApp />, document.getElementById('root'));