/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from "react";
import Spreadsheet from "react-spreadsheet";

const data = [
  [{ value: "Vanilla" }, { value: "Chocolate" }],
  [{ value: "Strawberry" }, { value: "Cookies" }]
];

export default () => {
  return (
    <div className="EarthDashboard">
      <Spreadsheet data={data} />
    </div>
  );
}