/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import React  from 'react';
import { EarthTabItem } from './EarthTabItem';
import './styles.scss';

export const EarthTabs = ({ children, className }) => (
  <div className={`EarthTabsContainer ${className ? className : ''}`}>
    <div className="EarthTabItemContainer">
      {children.map(({ props: p }, index) => (
        <EarthTabItem
          key={`earth-tab-item${index}`}
          icon={p.icon}
          iconDirection={p.iconDirection}
          title={p.title}
          isActive={p.isActive}
          onClick={p.onClick}
        />
      ))}
    </div>
    <div className="EarthTabItemBody">
      {children.map(({ props: p }, index) => (
        <div key={`earth-tab-item${index}`} hidden={!p.isActive}>
          {p.children}
        </div>
      ))}
    </div>
  </div>
);

EarthTabs.propTypes = {
  children: PropTypes.any,
};
