import React, { useState } from 'react';
import { EarthText } from './'
import settings from 'settings';
import { Button } from 'shards-react';

export const BalanceCard = ({ token, name, balance, avatar }) => (
  <div className='row mb-2'>
    <div className='col-2 col-md-2 col-lg-1 my-auto'>
      <img
        alt=''
        className='token-image'
        width={35} height={35} src={`${settings.API_URL}/static/images/${avatar}`}
      />
    </div>
    <div className='col-6 my-auto'>
      <EarthText size='xsmall'>{token}</EarthText>
      <EarthText>{name}</EarthText>
    </div>
    <div className='col-4 col-md-4 my-auto' align='right'>
      <EarthText>{`$${balance}`}</EarthText>
    </div>
  </div>
)

export const EditableBalanceCard = ({ token, name, balance, avatar, onSubmitChange }) => {
  const [newBalance, changeBalance] = useState(balance);
  return (
    <div className='row mb-2'>
      <div className='col-2 my-auto'>
        <img
          alt=''
          className='token-image'
          width={35} height={35} src={`${settings.API_URL}/static/images/${avatar}`}
        />
      </div>
      <div className='col-4 my-auto'>
        <EarthText size='xsmall'>{token}</EarthText>
        <EarthText>{name}</EarthText>
      </div>
      <div className='col-4 my-auto' align='right'>
        <input className='minimal-input' value={newBalance} onChange={({ target: { value } }) => changeBalance(value)} />
      </div>
      <div className='col-2 my-auto' align='right'>
        <Button
          disabled={newBalance === balance}
          onClick={() => onSubmitChange(name, newBalance - balance)}
        >
          <EarthText size='small'>SAVE</EarthText>
        </Button>
      </div>
    </div>
  )
}
