import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { sha3_256 } from 'js-sha3';
import { setLock } from 'store';
import settings from 'settings';

class LockScreen extends Component {

  state = { password: '' };

  onPasswordChange = ({ target: { value } }) => {
    this.setState({ password: value });
  }

  onSubmit = async () => {
    const { password } = this.state;
    const hashPw = sha3_256(password);
    const hashPwReal = await sessionStorage.getItem('hashPw');
    if (hashPw === hashPwReal) this.props.setLock(false);
    else this.setState({ password: '' })
  }

  render() {
    const { earthName } = this.props;
    const { password } = this.state;
    return (
      <div className="wrapper-page">

        <div className="card">
          <div className="card-body">

            <h3 className="text-center m-0">
              <Link to="/" onClick={() => this.props.UpdateLoginAgain()} className="logo logo-admin"><img src={settings.DARK_LOGO} height="30" alt="logo" /></Link>
            </h3>

            <div className="p-3">
              <h4 className="text-muted font-18 m-b-5 text-center">Locked</h4>
              <p className="text-muted text-center">Hello {earthName}, enter your password to unlock the screen!</p>

              <div className="form-horizontal m-t-30">

                <div className="user-thumb text-center m-b-30">
                  <img src="assets/images/users/user-6.jpg" className="rounded-circle img-thumbnail" alt="thumbnail" />
                  <h6>{earthName}</h6>
                </div>

                <div className="form-group">
                  <label htmlFor="userpassword">Password</label>
                  <input type="password" className="form-control" value={password}
                    onChange={this.onPasswordChange} id="userpassword" placeholder="Enter password"
                    required
                  />
                </div>

                <div className="form-group row m-t-20">
                  <div className="col-12 text-right">
                    <button className="btn btn-primary btn-login w-md waves-effect waves-light" onClick={this.onSubmit}>UNLOCK</button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div className="m-t-40 text-center">
          <p>Not you ? return <Link to="login" className=" text-primary"> Sign In </Link> </p>
        </div>

      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setLock: state => dispatch(setLock(state))
});

const mapStateToProps = state => ({
  earthName: state.root.user.earthName
});

export const Lock = connect(mapStateToProps, mapDispatchToProps)(LockScreen);
