import React from "react";
import { Text, TitledContainer } from "components";
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { EarthProof } from "components/EarthProof/EarthProof";
import proofIdentity from 'assets/images/proof-identity.png';
import proofResidency from 'assets/images/proof-residency.png';

const mapStateToProps = state => ({ user: state.root.user })
export default connect(mapStateToProps)(
  ({ user }) => (
    <TitledContainer screen fluid>
      <Row className='justify-content-center'>
        <Col xs={10} md={8} className='text-center' style={{ backgroundColor: 'white' }}>
          <img
            style={{ objectFit: 'cover', objectPosition: 'center' }}
            className="rounded-circle"
            src={user.avatar || require('assets/images/captainPlanet.jpg')}
            alt={user.earthName}
            height={150}
            width={150}
          />

          <Text fontStyle='bold'>{user.earthName}</Text>
          <Text size='xsmall'>Impact Level: Captain Planet</Text>
          <div className='divider' />

          <Text fontStyle='bold'>Account Details</Text>
          <Text>Email:{user.email}</Text>
          <Text>Access:{user.role}</Text>
          <Text>Preferred Currency:{user.currency.toUpperCase()}</Text>
          <Text>Preferred Language:{user.language.toUpperCase()}</Text>

          <div className='divider' />
          <Text size="large" className="mb-2 text-center ">
            Your account is authenticated
          </Text>
          <EarthProof
            title="Proof of Residency"
            isChecked={true}
            image={proofResidency}
            description="Utility Bill issued wihin 3 months"
          />
          <EarthProof
            isChecked={true}
            title="Proof of Identity"
            description="Passport scan or clear photo"
            image={proofIdentity}
          />
        </Col>
      </Row>
    </TitledContainer>
  )
);
