import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Logo, TitledContainer, Text, Button, Input } from 'components';
import './styles.scss';

export class EarthSignUp extends Component {
  state = { earthName: '', email: '', password: '', rePassword: '', loading: false };

  onUsernameChange = ({ target: { value } }) => {
    this.setState({ earthName: value });
  }

  onEmailChange = ({ target: { value } }) => {
    this.setState({ email: value });
  }

  onPasswordChange = ({ target: { value } }) => {
    this.setState({ password: value });
  }

  onRePasswordChange = ({ target: { value } }) => {
    this.setState({ rePassword: value });
  }

  onSignUpSubmit = e => {
    e.preventDefault();
    const { earthName, email, password } = this.state;
    this.props.onSignUpSubmit(earthName, email, password);
  }

  render() {
    const { earthName, password, email, rePassword, loading } = this.state;
    return (
      <TitledContainer screen fluid middle>
        <Row className='justify-content-center'>
          <Col xs={10} md={5} className='p-5' style={{ backgroundColor: 'white' }}>
            <Logo width={200} color='dark' wrap word />
            <form onSubmit={this.onSignUpSubmit} className="mt-5">
              <div className="form-group">
                <Text>
                  Your username
                </Text>
                <Input
                  onChange={this.onUsernameChange}
                  required
                  className="form-control"
                  placeholder={"Your username makes sending & receiving easy"}
                />
              </div>

              <div className="form-group">
                <Text>
                  Your email
                </Text>
                <Input
                  onChange={this.onEmailChange}
                  required
                  className="form-control"
                  placeholder="someone@mail.com"
                />
              </div>

              <div className="form-group mb-0">
                <Text>
                  Pick a password
                  </Text>
                <Input
                  type="password"
                  onChange={this.onPasswordChange}
                  className="form-control"
                  placeholder="•••••••••••"
                />
                <Text size='xsmall'>12 characters minimun. Letters and Numbers</Text>
              </div>

              <div className="form-group">
                <Text>
                  Repeat your password
                  </Text>
                <Input
                  onChange={this.onRePasswordChange}
                  type="password"
                  name="confirm-password"
                  className="form-control"
                  placeholder="•••••••••••"
                />
              </div>

              <Button
                type="submit" className="w-100"
                disabled={!(earthName && email && password && rePassword && password === rePassword) || loading}
                loading={loading}
                label='sign up'
              />
              <Text className='mt-2' size='small' center onClick={this.props.swapTab}>Already have an account?</Text>
            </form>
          </Col>
        </Row>
      </TitledContainer>


    );
  }
}
