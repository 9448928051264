/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import { Container, Card, CardHeader, CardBody, FormInput } from "shards-react";
import { EarthActivityWithButtons, EarthModal, EarthText } from '../../components';
import { alerts } from '../../controllers/requests';
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";

class AlertsManagement extends React.Component {
  state = { showModal: false, alertMessage: '', feed: [] }

  async componentDidMount() {
    const feed = await alerts.getAlerts();
    const feedSanitized = feed.map(item => ({ id: item.alertId, title: item.location, description: item.label }))
    this.setState({ feed: feedSanitized });
  }

  onNewAlertClick = () => {
    this.setState({ showModal: true });
  }

  onRemoveAlert = async alertId => {
    const res = await alerts.removeAlert(alertId);
    if(res) toast.success('Success')
    else toast.error('error');
  }

  onNewAlertSubmit = async () => {
    const { alertLocation, alertMessage } = this.state;
    const res = await alerts.newAlert(alertLocation, alertMessage)
    if(res) toast.success('Success')
    else toast.error('error');
  }

  render() {
    const { feed, showModal } = this.state;

    return (
      <Container fluid className="main-content-container px-4">
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <h6 className="m-0">Alerts</h6>
            <Button color='primary' onClick={this.onNewAlertClick}>
              New alert
            </Button>
          </CardHeader>
          <CardBody className="p-0 pb-3">
            <EarthActivityWithButtons onEdit={null} onRemove={this.onRemoveAlert} activities={feed} />
          </CardBody>
        </Card>
        <EarthModal
          show={showModal}
          onClose={() => this.setState({ showModal: false })}
        >
          <EarthText className="text-center mb-4" size="xlarge" fontStyle="bold">New alert</EarthText>
          <FormInput
            placeholder='Location'
            onChange={({ target: { value } }) => this.setState({ alertLocation: value })}
          />
          <FormInput
            placeholder='Message'
            onChange={({ target: { value } }) => this.setState({ alertMessage: value })}
          />
          <Button className='w-100' color="primary" onClick={this.onNewAlertSubmit}>
            Confirm
          </Button>
        </EarthModal>
      </Container>
    );
  }
}

export default AlertsManagement;