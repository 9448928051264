import React from 'react';
import { Text } from './Text';
import { Button as DefaultButton } from 'reactstrap';
import { mergeStyles } from 'utils';
import { Spinner } from './Spinner';

const smallStyle = {
  paddingBottom: '0.25rem',
  paddingTop: '0.25rem',
  paddingLeft: '1rem',
  paddingRight: '1rem',
}

export const Button = ({ label, small, color, fill, loading, square, ...rest }) => (
  <DefaultButton
    {...rest}
    disabled={loading || rest.disabled}
    color={color || 'primary'}
    style={mergeStyles([
      {
        borderRadius: 20,
      },
      small && smallStyle,
      rest.style
    ])}
    outline={!fill}
  >
    {
      loading ?
        <Spinner width={20} />
        :
        <Text din size={small ? 'xsmall' : 'small'} style={{ margin: 0 }}>{label}</Text>
    }
  </DefaultButton>
)