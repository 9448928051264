import React from "react";
import PropTypes from "prop-types";
import Spinner from 'react-spinner-material';
import Chart from "../../../utils/chart";

class Cake extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  upgradeChart = () => {
    if (this.props.loading) return true;
    const { tokens } = this.props;
    const fullAmount = tokens.map(token => Number(token.balance)).reduce((a, b) => a + b, 0)
    const tokensWithPercentages = tokens.map(({ token, balance }) => ({ token, percentage: ((balance / fullAmount) * 100).toFixed(1) }))

    const chartData = {
      datasets: [
        {
          data: tokensWithPercentages.map(({ percentage }) => percentage),
          borderColor: '#20273D',
          borderWidth: 5,
          backgroundColor: [
            "rgba(32,64,112,0.5)",
            "rgba(157,37,99,0.5)",
            "rgba(191,173,153,0.5)",
            "rgba(138,196,38,0.5)",
          ]
        }
      ],
      labels: tokensWithPercentages.map(({ token, percentage }) => `${token}: ${percentage}%`)
    }
    const chartConfig = {
      type: "doughnut",
      data: chartData,
      options: {
        legend: {
          position: 'right',
          labels: {
            fontColor: 'white',
            fontSize: 14,
          }
        }
      }
    };

    new Chart(this.canvasRef.current, chartConfig);
  }
  
  componentDidMount() {
    this.upgradeChart();
  }

  componentDidUpdate(prevProps) {
    this.upgradeChart();
  }

  
  render() {
    return (
      <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
        {
          this.props.loading ?
            <div style={{ width: 120, margin: 'auto' }}>
              <Spinner size={80} spinnerColor={"#FFF"} spinnerWidth={2} visible={true} />
            </div>
            :
            <div style={{ maxWidth: '80%', marginTop: -20 }}>
              <canvas
                height="220"
                ref={this.canvasRef}
                className="blog-users-by-device m-auto"
              />
            </div>

        }
      </div>
    );
  }
}

Cake.propTypes = {
  /**
   * The chart config object.
   */
  chartConfig: PropTypes.object,
  /**
   * The Chart.js options.
   */
  chartOptions: PropTypes.object,
  /**
   * The chart data.
   */
  chartData: PropTypes.object
};

Cake.defaultProps = {
  title: "Users by device",
  chartData: {
    datasets: [
      {
        hoverBorderColor: "#ffffff",
        data: [25, 25, 25, 25],
        backgroundColor: [
          "rgba(0,123,255,0.9)",
          "rgba(0,123,255,0.5)",
          "rgba(0,123,255,0.3)",
          "rgba(0,123,255,0.1)",
        ]
      }
    ],
    labels: ["KGT", "FCT", "CET", "EST"]
  }
};

export default Cake;
