import React from 'react';
import { EarthText } from '../../../components';

export const EarthQuestion = ({ question, answer }) => (
  <div>
    <EarthText fontStyle='bold'>
      {question}
    </EarthText>
    <EarthText size='small' fontStyle='light'>
      {answer}
    </EarthText>
  </div>
);
