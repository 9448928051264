import React, { Component } from 'react';
import { saveToken, users } from 'controllers/requests';
import { sha3_256 } from 'js-sha3';
import { toast } from 'react-toastify';
import { Row, Col } from 'reactstrap';
import { Logo, TitledContainer, Text, Button, Input } from 'components';
import './styles.scss';

export class EarthSignIn extends Component {
  state = { earthName: '', password: '', loading: false };

  onUsernameChange = ({ target: { value } }) => this.setState({ earthName: value });
  onPasswordChange = ({ target: { value } }) => this.setState({ password: value });

  onSignInSubmit = async e => {
    e.preventDefault();
    this.setState({ loading: true });
    const { earthName, password } = this.state;
    const { token, active, kyc } = await users.signIn(earthName, password);
    this.setState({ loading: false });
    saveToken(token);
    if (token) {
      if (active) {
        sessionStorage.setItem('authToken', token);
        sessionStorage.setItem('hashPw', sha3_256(password).toString('hex'));
      }
      toast.success('Sign in succesful!');
      this.props.onSignInSubmit({ loggedIn: true, active, kyc });
    } else toast.error('Failed to sign in!');
  }

  render() {
    const { earthName, password, loading } = this.state;
    return (
      <TitledContainer screen fluid middle>
        <Row className='justify-content-center'>
          <Col xs={10} md={5} className='p-5' style={{ backgroundColor: 'white' }}>
            <Logo width={200} color='dark' wrap word />
            <form onSubmit={this.onSignInSubmit} className="mt-5">
              <div className="form-group">
                <Text size='small'>Username</Text>
                <Input
                  onChange={this.onUsernameChange}
                  value={earthName}
                  className="mr-2 mt-2 mb-4"
                  placeholder={"Your Username or Email"}
                  required
                />
              </div>
              <div className="form-group">
                <Text size='small'>Password</Text>
                <Input
                  onChange={this.onPasswordChange}
                  value={password}
                  type="password"
                  className="mr-2 mt-2 mb-4"
                  placeholder="Your Password"
                  required
                />
              </div>
              <Button
                className='w-100'
                type='submit'
                disabled={!(earthName && password) || loading}
                loading={loading}
                label='sign in'
              />
              <Text className='mt-2' size='small' center onClick={this.props.swapTab}> Don't have an account?</Text>
            </form>
          </Col>
        </Row>
      </TitledContainer>
    );
  }
}