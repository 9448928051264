const errors = {
  INVALID_INEXISTING_PROJECT: "The project you want to access no longer exists.",
  INVALID_INSUFICIENT_FUNDS: "Insuficient funds",
  INVALID_PAYMENT_METHOD: "Invalid card or billing details.",
  ERROR_WHILE_CREATING: "Error while creating project, please contact support",
  ERROR_WHILE_REGISTERING: "Your donation was successfully acredited but we had trouble registering it. Contact support if you want us to register it for future benefits.",
  ERROR_WHILE_EMAIL: "Your donation was successfully acredited but we had trouble sending an email to you. Contact support if you want us to register it for future benefits.",
  ERROR_WHILE_PAYING: "Error while processing your payment, please check your card details.",
  ERROR_NOT_FOUND: "Requested resource not found",
  INVALID_DONATION_VALUE: "The minimum donation we can accept is 1 USD.",
  MISSING_PRIVACY_INFO: "We don't have any information linked to this email.",
  INVALID_PERSONAL_INFO: "Invalid personal details, please check you are not using symbols in your name",
  UNMATCHING_UNSUBSCRIBE_INFO: "No active subscriptions found with that combination",
  ERROR_WITH_INFO: "We had trouble with your personal information, please check it has no symbols.",
  UNSUBSCRIBING_ERROR: "Error while unsubscribing, please contact support."
}

export default errorCode => {
  return errors[errorCode] || 'Error while processing request, please try again later.';
}