import React from 'react';
import { Row, Col } from 'reactstrap';
import { Box, Text } from 'components';
import numeral from 'numeral';
import moment from 'moment';

const icons = {
  purchase: 'monetization_on',
  admin: 'supervisor_account'
}

export const Activity = ({ activity }) => (
  <Box className='activity'>
    {
      activity.length ?
        activity.map(({ type, amount, value, cost, timestamp, label }, index) => (
          <Row key={index} className='mb-2'>
            <Col lg={4} xs={5} style={{ display: 'flex' }}>
              <i className='material-icons inline' style={{ fontSize: 14, paddingRight: 2 }}>{icons[type]}</i>
              <Text din dark size='xsmall' className='inline'>{label}</Text>
            </Col>
            <Col lg={3} xs={3}>
              <Text dark size='xsmall'>
                {
                  amount ?
                    `${amount} unit x ${numeral(cost).format('$0,[0]a')}`
                    :
                    value
                }
              </Text>
            </Col>
            <Col lg={5} xs={4}>
              <Text dark size='xsmall'>{moment(timestamp).calendar()}</Text>
            </Col>
          </Row>
        ))
        :
        <Box fluid middle center>
          <Text>No activity yet!</Text>
        </Box>
    }
  </Box>
)