import React, { Component } from 'react';
import './styles.scss';

const SDG = [
  { image: require('../../assets/images/sdgs/1.jpg') },
  { image: require('../../assets/images/sdgs/2.jpg') },
  { image: require('../../assets/images/sdgs/3.jpg') },
  { image: require('../../assets/images/sdgs/4.jpg') },
  { image: require('../../assets/images/sdgs/5.jpg') },
  { image: require('../../assets/images/sdgs/6.jpg') },
  { image: require('../../assets/images/sdgs/7.jpg') },
  { image: require('../../assets/images/sdgs/8.jpg') },
  { image: require('../../assets/images/sdgs/9.jpg') },
  { image: require('../../assets/images/sdgs/10.jpg') },
  { image: require('../../assets/images/sdgs/11.jpg') },
  { image: require('../../assets/images/sdgs/12.jpg') },
  { image: require('../../assets/images/sdgs/13.jpg') },
  { image: require('../../assets/images/sdgs/14.jpg') },
  { image: require('../../assets/images/sdgs/15.jpg') },
  { image: require('../../assets/images/sdgs/16.jpg') },
  { image: require('../../assets/images/sdgs/17.jpg') }
];

const SDGButton = ({ image, selected, smaller, onSelect }) => (
  <img
    className={`sdg-button ${selected ? 'sdg-button-active' : ''} ${smaller ? 'smaller' : ''}`}
    alt='sdg' src={image} onClick={onSelect}
  />
)

export class SDGButtons extends Component {
  onChange = selected => {
    const { onChange } = this.props;
    const newValues = { ...this.props.values };
    newValues[selected] = !newValues[selected];
    onChange && onChange(newValues);
  }

  render() {
    const { values={}, onlyShow, smaller, endToStart } = this.props;
    return (
      <div className='row sdg-menu' style={endToStart && { flexFlow: 'row-reverse' }}>
        {
          SDG.map(({ image }, id) => {
            if (onlyShow) {
              if (onlyShow[id]) {
                return <SDGButton key={id} smaller={smaller} image={image} onSelect={() => this.onChange(id)} selected={values[id]} />
              } else return null;
            } else return <SDGButton key={id} smaller={smaller} image={image} onSelect={() => this.onChange(id)} selected={values[id]} />
          })
        }
      </div>
    );
  }
}
