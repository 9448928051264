import React from "react";
import { connect } from 'react-redux';
import { setCurrencyModal, setLoading } from 'store';
import { Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from "reactstrap";

class Profile extends React.Component {
  state = { dropdownOpen: false }
  toggle = () => this.setState({ dropdownOpen: !this.state.dropdownOpen })
  onLogOut = () => {
    sessionStorage.clear();
    this.props.setLoading(true);
  };

  navigate = (e, where) => {
    e.preventDefault();
    this.props.history.push(where)
  }

  render() {
    const { earthName, role } = this.props;
    return (
      <Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle caret>
          <i className="material-icons user-icon white mr-2">account_circle</i>
          {earthName}
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={e => this.navigate(e, '/profile')}>
            <i className="material-icons my-auto mr-2">account_circle</i>
            User details
          </DropdownItem>
          {
            role === 'admin' &&
            <DropdownItem onClick={e => this.navigate(e, '/admin')}>
              <i className="material-icons my-auto mr-2">supervisor_account</i>
              Admin Settings
            </DropdownItem>
          }
          <DropdownItem>
            <i className="material-icons my-auto mr-2">money</i>
            Currency
          </DropdownItem>
          <DropdownItem onClick={e => this.navigate(e, '/settings/security')}>
            <i className="material-icons my-auto mr-2">security</i>
            Security
          </DropdownItem>
          <DropdownItem onClick={e => this.navigate(e, '/settings')}>
            <i className="material-icons my-auto mr-2">build</i>
            Settings
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={this.onLogOut}>
            <i className="material-icons my-auto mr-2">power_settings_new</i>
            Log out
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

const mapStatetoProps = state => {
  return {
    earthName: state.root.user.earthName,
    role: state.root.user.role,
  };
}

const mapDispatchToProps = dispatch => ({
  setCurrencyModal: state => dispatch(setCurrencyModal(state)),
  setLoading: state => dispatch(setLoading(state))
});

export default connect(mapStatetoProps, mapDispatchToProps)(Profile);
