import PropTypes from 'prop-types';
import React from 'react';
import { Text } from 'components';
import { Button } from 'shards-react';
import './styles.scss';

const EarthActivityItem = ({ type, title, description, amount, date, disabled, onEdit, onRemove }) => {
  return (
    <div className="EarthActivityItemButtons row">
      <div className='col-1'>
        {
          onRemove &&
          <Button size="sm" outline theme="danger" onClick={onRemove} disabled={disabled} className="p-0">
            <i className='material-icons'>delete</i>
          </Button>
        }
        {
          onEdit &&
          <Button size="sm" outline theme="info" onClick={onEdit} disabled={disabled} className="p-0">
            <i className='material-icons'>edit</i>
          </Button>
        }
      </div>

      <div className="col-7">
        <Text className="EarthActivityItemButtons__title" size="small" fontStyle="bold">
          {title}
        </Text>
        <Text className="EarthActivityItemButtons__description" size="xsmall">
          {description}
        </Text>
      </div>

      <div className="col-3">
        <Text className="EarthActivityItemButtons__amount" size="small" fontStyle="bold">
          {amount}
        </Text>
        <Text className="EarthActivityItemButtons__date" size="xsmall">
          {date}
        </Text>
      </div>
    </div>
  );
};

EarthActivityItem.propTypes = {
  type: PropTypes.oneOf(['active', 'inactive']).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  amount: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};


export const EarthActivityWithButtons = ({ activities, className, onEdit, onRemove, disabled }) => {
  return activities.map(
    ({ id, type, initial, title, description, amount, date }, index) => (
      <EarthActivityItem
        key={`eart-activity-card-${index}`}
        type={type}
        disabled={disabled}
        onEdit={onEdit}
        onRemove={onRemove ? () => onRemove(id) : undefined}
        initial={initial}
        title={title}
        description={description}
        amount={amount}
        date={date}
      />
    )
  )
};

EarthActivityWithButtons.propTypes = {
  className: PropTypes.string,
  activities: PropTypes.PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['active', 'inactive']).isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      amount: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
    }).isRequired
  ),
};
