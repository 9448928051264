import React from 'react';
import { Container, Text } from '.';
import { Row, Col, Spinner } from 'reactstrap';
import { mergeStyles } from 'utils';

const fluidStyle = {
  display: 'flex',
  flexDirection: 'column'
}

export const TitledContainer = ({ screen, loading, fluid, title, box, backgroundColor, middle, renderRightTitle = () => null, children, style, className }) => {

  const containerStyles = mergeStyles([
    {
      paddingTop: screen ? 20 : 0,
      paddingBottom: screen ? 50 : 0,
    },
    middle && { margin: 'auto' },
    fluid && fluidStyle,
    backgroundColor && { backgroundColor: typeof backgroundColor === 'string' ? backgroundColor : 'white' },
    style
  ]);

  return (
    <Container style={containerStyles} box={box} className={className}>
      {
        loading ?
          <Spinner />
          :
          <>
            <Row style={{
              marginBottom: 10
            }}>
              <Col xs={7} style={{ whiteSpace: 'nowrap' }}>
                <Text dark din size={screen ? 'large' : 'medium'}>{title}</Text>
              </Col>
              <Col style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                {renderRightTitle()}
              </Col>
            </Row>
            {children}
          </>
      }
    </Container >
  )
}