import React, { useState, useEffect } from 'react';
import { isJson } from 'utils';
import { users } from 'controllers/requests';
import { connect } from 'react-redux';
import { Text, Spinner, Box, MarginBox, Button, TitledContainer } from 'components';

let childWindow = null;
let messageListener = null;
let timer = null;

const LinkAccount = props => {
  const [isConnecting, setConnecting] = useState(false);
  const [registering, setRegistering] = useState(false);
  const [connected, setConnected] = useState(false);
  const [failed, setFailed] = useState(false);
  const popupwindow = (url, title, w, h) => {
    var left = (window.screen.width / 2) - (w / 2);
    var top = (window.screen.height / 2) - (h / 2);
    return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
  }

  const handleMessage = e => {
    if (e.origin === document.baseURI.substr(0, document.baseURI.length -1)) {
      if (isJson(e.data)) {
        const message = JSON.parse(e.data);
        if (message.error) {
          setFailed(true);
          setConnected(false);
        } else {
          const queryParams = Object.fromEntries(new URLSearchParams(message.location.search));
          setConnecting(false);
          setRegistering(true);
          setTimeout(() => {
            console.log(queryParams)
            users.linkBankAccount(queryParams).then(res => {
              setRegistering(false);
              setConnected(true);
            })
          }, 2000)
          setFailed(false);
          childWindow && childWindow.close();
          messageListener = null;
          window.removeEventListener('message', handleMessage);
        }
      }
    }
  }

  useEffect(() => {
    if (!messageListener) window.addEventListener('message', handleMessage);
  }, []);

  useEffect(() => {
    if (connected) setTimeout(() => {
      props.history.push('/account')
    }, 3000)
  }, [connected])

  const onClickLink = () => {
    setConnecting(true)
    childWindow = popupwindow(
      process.env.NODE_ENV === 'development'?
      'https://link.tink.com/1.0/authorize/?client_id=7ab403d54a594554a192c326385ea489&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Faccount%2Flink%2Fcallback%2Ftink&scope=accounts:read,investments:read,transactions:read,user:read,credentials:read,identity:read,statistics:read&market=GB&locale=en_US'
      :
      'https://link.tink.com/1.0/authorize/?client_id=7ab403d54a594554a192c326385ea489&redirect_uri=https%3A%2F%2Fapp.earthbanc.io%2Faccount%2Flink%2Fcallback%2Ftink&scope=accounts:read,investments:read,transactions:read,user:read&market=SE&locale=en_US',
      'asd',
      357,
      600
    )
    timer = setInterval(function () {
      if (childWindow.closed) {
        clearInterval(timer);
        setConnecting(false)
      }
    }, 1000);
  }

  const renderWhileConnecting = () => (
    <>
      <Text din center>Connecting</Text>
      <Spinner />
    </>
  )

  const renderShouldLink = () => (
    <>
      <Text din center>Connect your bank account to get access to all the features</Text>
      <img
        alt='bank-explanation'
        src={require('assets/BankExplanation.png')}
        style={{
          width: '100%',
          maxWidth: 400,
          objectFit: 'contain'
        }}
      />
    </>
  )

  const renderOnError = () => (
    <Text din center>We've encountered an error while retrieving your credentials, please try again.</Text>
  )

  const renderOnConnected = () => (
    <Text din center>Account linked, redirecting in 3 seconds...</Text>
  )

  const renderOnRegistering = () => (
    <Text din center>Linking account...</Text>
  )

  return (
    <TitledContainer screen
      title='Link your account'
      renderRightTitle={() => (
        <Button
          loading={isConnecting}
          onClick={onClickLink}
          className='my-auto' label='Link'
        />
      )}
    >
      {JSON.stringify(props.location.query)}
      <MarginBox>
        <Box fluid middle center>
          {isConnecting && renderWhileConnecting()}
          {!isConnecting && !failed && !connected && !registering && renderShouldLink()}
          {!isConnecting && failed && !connected && !registering && renderOnError()}
          {!isConnecting && !failed && registering && renderOnRegistering()}
          {!isConnecting && !failed && connected && renderOnConnected()}
        </Box>
      </MarginBox>
    </TitledContainer>
  )
}

const mapStatetoProps = state => ({ ...state.root.user });
export default connect(mapStatetoProps)(LinkAccount);