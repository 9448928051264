import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';
import { Modal } from 'react-bootstrap';

export const EarthModal = ({ show, onClose, fix, children, size }) => {
  return (
    <Modal show={show} centered>
      <button type="button" className="close" onClick={onClose}>
        <span className="material-icons p5">close</span>
      </button>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

EarthModal.defaultProps = {
  fix: false,
  show: false,
};

EarthModal.propTypes = {
  fix: PropTypes.bool,
  show: PropTypes.bool,
  children: PropTypes.any,
  size: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};
