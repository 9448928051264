/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import { FormSelect } from "shards-react";
import { Text, TitledContainer, MarginBox } from "components";

export default () => {
  return (
    <TitledContainer screen title='Settings' fluid>
      <MarginBox>
        <div className='form-group'>
          <Text>Language</Text>
          <FormSelect>
            <option>English</option>
            <option>Spanish</option>
          </FormSelect>
        </div>
        <div className='form-group'>
          <Text>Currency</Text>
          <FormSelect>
            <option>USD</option>
            <option>ARS</option>
            <option>EUR</option>
          </FormSelect>
        </div>
      </MarginBox>
    </TitledContainer>
  )
}