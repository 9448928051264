const settings = {
  production: {
    API_URL: 'https://api.earthbanc.io',
    LIGHT_LOGO: require('assets/icons/icon.svg'),
    DARK_LOGO: require('assets/icons/icon.svg')
  },
  development: {
    API_URL: 'https://api.earthbanc.io',
    LIGHT_LOGO: require('assets/icons/icon.svg'),
    DARK_LOGO: require('assets/icons/icon.svg')
  }
}

export default settings[process.env.NODE_ENV];