import React from 'react';
import { Navbar as NavBar, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import { Text, Logo } from 'components';
import Profile from './ProfileDropdown'
import Notifications from './NotificationsDropdown';

export const EarthHeader = ({ history }) => {
  const navigate = (e, where) => {
    e.preventDefault();
    history.push(where)
  }

  return (
    <NavBar className='navbar-colored only-desktop' color="dark" light expand="md">
      <div className='container'>
        <NavbarBrand href="/">
          <Logo word light width={200} />
        </NavbarBrand>
        <Nav className='navbar-container' navbar>
          <NavItem>
            <NavLink href="/dashboard" onClick={e => navigate(e, '/account')}>
              <Text din size='medium'>Account</Text>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/impact" onClick={e => navigate(e, '/impact')}>
              <Text din size='medium'>Earth Saver</Text>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/projects" onClick={e => navigate(e, '/projects')}>
              <Text din size='medium'>Projects</Text>
            </NavLink>
          </NavItem>
        </Nav>
        <Nav navbar>
          <Notifications />
          <Profile history={history} />
        </Nav>
      </div>
    </NavBar>
  )
}
