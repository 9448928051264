const createAction = type => state => ({ type, payload: state })

export const updateSavingsBalance = createAction('UPDATE_SAVINGS_BALANCE')
export const updateExchangeRates = createAction('UPDATE_EXCHANGE_RATES')
export const updateTotalBalance = createAction('UPDATE_TOTAL_BALANCE')
export const updateLastInterest = createAction('UPDATE_LAST_INTEREST')
export const updateChoosenFiat = createAction('UPDATE_CHOOSEN_FIAT')
export const setCurrencyModal = createAction('SET_CURRENCY_MODAL')
export const updateActivity = createAction('UPDATE_ACTIVITY')
export const toggleSidebar = createAction('TOGGLE_SIDEBAR')
export const updateBackup = createAction('UPDATE_BACKUP')
export const setLoading = createAction('SET_LOADING')
export const updateUser = createAction('UPDATE_USER')
export const setLock = createAction('SET_LOCK')