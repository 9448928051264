import React from 'react';
import { mergeStyles } from 'utils';

const smallStyle = {
  paddingBottom: '0.25rem',
  paddingTop: '0.25rem',
  paddingLeft: '1rem',
  paddingRight: '1.5rem',
}

export const Dropdown = ({ options, onChange, selected }) => (
  <div className='select-wrapper'>
    <select
      value={selected}
      onChange={e => onChange(e.target.value)}
      style={mergeStyles([
        smallStyle
      ])}
    >
      {
        options.map(
          ({ value, label }, index) => (
            <option key={index} value={value}>{label || value}</option>
          )
        )
      }
    </select>
  </div>
)