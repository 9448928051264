import React from 'react';

const cachedIcons = {
  user: 'admin.svg',
  'down-arrow': 'down-arrow.svg',
  icon: 'icon.svg',
  trees: 'impact.svg',
  notifications: 'notifications.svg',
  money: 'price.svg',
  projects: 'projects.svg',
  cloud: 'cloud.svg'
};

export const CachedIcon = ({ size = 30, name }) => (
  <img src={require(`assets/icons/${cachedIcons[name]}`)} height={30} alt='' />
)