import React from "react";
import { connect } from 'react-redux';
import { users, saveToken } from 'controllers/requests';
import { updateUser } from 'store';
import settings from 'settings';
import { Spinner } from "components";

class Loading extends React.Component {

  async componentDidMount() {
    const hashPw = await sessionStorage.getItem('hashPw');
    const authToken = await sessionStorage.getItem('authToken');
    saveToken(authToken);
    if (hashPw && authToken) {
      const user = await users.getSelf()
      if (!user) {
        sessionStorage.clear();
        setTimeout(() => this.props.onLoadingComplete(false), 500)
      }
      this.props.updateUser(user);
      const { pathname, search } = window.location;
      setTimeout(() => this.props.onLoadingComplete(true, pathname + search), 500)
    } else {
      sessionStorage.clear();
      setTimeout(() => this.props.onLoadingComplete(false), 500)
    }
  }

  render() {
    return (
      <div className='row justify-content-center' style={{ height: '100vh', width:'100vw', overflow: 'hidden' }}>
        <div className='col-10 col-md-6 col-xl-4 my-auto text-center fade-in'>
          <img
            src={settings.LIGHT_LOGO}
            className='mb-4'
            alt='logo'
            width={200}
          />
          <Spinner />
        </div>
      </div>
    )
  }
};


const mapDispatchtoProps = dispatch => {
  return {
    updateUser: state => dispatch(updateUser(state)),
  };
}

export const AppLoading = connect(null, mapDispatchtoProps)(Loading);