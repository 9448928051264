import axios from 'axios';
import { getToken } from './index';
import HTTPError from '../HTTPError';
import settings from 'settings';

export const utils = {
  getAlerts: async () => {
    const url = settings.API_URL + '/feed'
    try {
      const res = await axios.get(url, { headers: { 'content-type': 'multipart/form-data', Authorization: getToken() } });
      return res.data;
    } catch (err) {
      return false;
    }
  }
}