import React from "react";
import { Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from "reactstrap";
import { Text } from "components";

export default class Notifications extends React.Component {
  state = { dropdownOpen: false }
  toggle = () => this.setState({ dropdownOpen: !this.state.dropdownOpen })

  render() {
    return (
      <Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle caret>
          <i className='material-icons mr-3 my-auto'>notifications</i>
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem>
            <Text din bold>Ooops, nothing here yet!</Text>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }
}