/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import { FormInput } from "shards-react";
import './styles.scss';
import { EarthMarketList } from './EarthMarketList';
import { EarthMarketOrderList } from './EarthMarketOrderList';
import { EarthMarketHistory } from './EarthMarketHistory'
import { BuySellForm } from './BuySellForm'
import { EarthMarketOverview } from './EarthMarketOverview';
import { balances } from 'controllers/requests';
import { MarginBox } from "components";
import { TitledContainer } from "components";

class Projects extends React.Component {

  state = { sdg: 0, equityTokens: [], selectedMarket: '', filter: '' };

  componentDidMount() {
    balances.getBalances().then(equityTokens => this.setState({ equityTokens }))
  }

  onSDGSelection = () => {

  }

  renderPortfolioCard = () => null

  renderMarketCard = () => (
    <MarginBox fluid>
      <TitledContainer
        title='Market List'
        renderRightTitle={() => <FormInput
          style={{ height: 22 }}
          onChange={({ target: { value } }) => this.setState({ filter: value })}
        />}
      >
        <EarthMarketList
          filter={this.state.filter}
          selectedMarket={this.state.selectedMarket}
          navigate={selectedMarket => this.setState({ selectedMarket })}
        />
      </TitledContainer>
    </MarginBox>
  )

  renderMarketOverview = () => (
    <EarthMarketOverview
      market={this.state.selectedMarket}
    />
  )

  renderBuySell = () => (
    <BuySellForm
      market={this.state.selectedMarket}
    />
  )

  renderSellOrders = () => (
    <MarginBox fluid>
      <TitledContainer title='Buy Orders'>
        <EarthMarketOrderList type='sell' market={this.state.selectedMarket} />
      </TitledContainer>
    </MarginBox>
  )

  renderBuyOrders = () => (
    <MarginBox fluid>
      <TitledContainer title='Sell Orders'>
        <EarthMarketOrderList type='buy' market={this.state.selectedMarket} />
      </TitledContainer>
    </MarginBox>
  )

  renderMarketHistory = () => (
    <MarginBox fluid>
      <TitledContainer title='Historical Orders'>
        <EarthMarketHistory market={this.state.selectedMarket} />
      </TitledContainer>
    </MarginBox>
  )

  render() {
    return (
      <TitledContainer screen fluid title='Market Overview'>
        <div className='row'>
          <div className="col">
            {this.renderPortfolioCard()}
          </div>
        </div>
        <div className='row mb-2'>
          <div className='col-12 col-xl-3'>
            {this.renderMarketCard()}
          </div>
          <div className='col-12 col-xl-6'>
            {this.renderMarketOverview()}
          </div>
          <div className='col-12 col-xl-3'>
            {this.renderBuySell()}
          </div>
        </div>
        <div className='row'>
          <div className='col-12 col-xl-3'>
            {this.renderSellOrders()}
          </div>
          <div className='col-12 col-xl-3'>
            {this.renderBuyOrders()}
          </div>
          <div className='col-12 col-xl-6'>
            {this.renderMarketHistory()}
          </div>
        </div>
      </TitledContainer>
    );
  }
}

export default Projects;