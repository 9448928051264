import React, { Component } from "react";
import FileZone from 'react-files';
import { isAlpha, isNumeric } from "../../utils/validations";
import "react-quill/dist/quill.snow.css";
import "./quill.scss";
import { MarginBox } from "components/MarginBox";
import countryNames from '../../utils/countries';
import { TitledContainer } from "components/TitledContainer";
import StepWizard from 'react-step-wizard';
import { Row, Col, Badge } from "reactstrap";
import { Text } from "components/Text";
import { Button } from "components/Button";
import { Input } from "components/Input";
import { getImage } from "utils";
import { Dropdown } from "components/Dropdown";

export class ProjectEditor extends Component {
  state = {
    name: '', description: '', price: '',
    avatar: '', images: '', location: '', unitPrice: '',
    category: '', urlPath: '', unitName: '', unitAmount: '',
  };

  componentDidMount() {
    const { editContent } = this.props;
    if (editContent) this.setState({ ...editContent });
  }

  onInputChange = (field, { target: { value } }) => this.setState({ [field]: value });
  onUploadAvatar = avatarList => this.setState({ avatar: avatarList[0] });
  onUpload = images => this.setState({ images });
  removeImage = file => {
    const { images } = this.state;
    images.map((image, id) => {
      if (image.id === file.id) images.splice(id, 1);
    })
    this.setState({ images });
    this.refs.images.removeFile(file);
  }
  removeAvatar = avatar => {
    this.setState({ avatar: '' })
    this.refs.images.removeFile(avatar);
  }

  onSubmit = () => {
    const {
      name, description, price,
      avatar, images, location, unitPrice,
      category, urlPath, unitName, unitAmount
    } = this.state;

    const project = {
      name, description, price,
      avatar, images, location, unitPrice,
      category, urlPath, unitName, unitAmount
    }
    this.setState({ publishing: true });
    this.props.onSubmit(project).then(() => this.setState({ publishing: false }));
  }

  Step = ({ currentStep, goToStep, stepName, totalSteps, nextAvailable, children }) => {
    return (
      <TitledContainer fluid title={stepName}>
        {children}
        <Row style={{ justifyContent: 'flex-end', paddingRight: 20, marginTop: 10 }}>
          {currentStep !== 1 && <Text style={{ marginRight: 20 }} din bold onClick={() => goToStep(currentStep - 1)}>Previous</Text>}
          {currentStep !== totalSteps && <Text invalid={!nextAvailable} din bold onClick={() => goToStep(currentStep + 1)}>Next</Text>}
          {currentStep === totalSteps && <Button din bold label='Publish' loading={this.state.publishing} disabled={!nextAvailable} onClick={this.onSubmit} />}
        </Row>
      </TitledContainer>
    )
  }

  renderStepOne = props => {
    const { name, location, description, category } = this.state;
    const isNameValid = !!name;
    const isLocationValid = !!location;
    const isCategoryValid = !!category;
    const isDescriptionValid = !!description;
    const isNextAvailable = isNameValid && isLocationValid && isCategoryValid && isDescriptionValid;
    return (
      <this.Step {...props} nextAvailable={isNextAvailable} stepName='Details' >
        <Row>
          <Col lg={5} xs={12}>
            <Text din bold invalid={!isNameValid} size='small'>Project Name</Text>
            <Input value={name} onChange={e => this.onInputChange('name', e)} />
          </Col>
          <Col lg={5} xs={12}>
            <Text din bold invalid={!isLocationValid} size='small'>Where is it?</Text>
            <Dropdown
              selected={this.state.location} onChange={location => this.setState({ location })}
              options={countryNames.map(({ name }) => ({ value: name, label: name }))}
            />
          </Col>
        </Row>
        <Row className='mt-2 mb-2'>
          <Col lg={5} xs={12}>
            <Text din bold invalid={!isDescriptionValid} size='small'>Project Description</Text>
            <Input value={description} onChange={e => this.onInputChange('description', e)} />
          </Col>
          <Col lg={5} xs={12}>
            <Text din bold invalid={!isCategoryValid} size='small'>Category</Text>
            <Input value={category} onChange={e => this.onInputChange('category', e)} />
          </Col>
        </Row>
      </this.Step>
    )
  }

  renderStepTwo = props => {
    const { urlPath, unitName, unitPrice, unitAmount } = this.state;
    const isUrlPathValid = isAlpha(urlPath, true);
    const isPriceValid = isNumeric(unitPrice) && Number(unitPrice) > 0;
    const isAmountValid = isNumeric(unitAmount) && Number(unitAmount) > 0;
    const isUnitNameValid = unitName?.length === 3;
    const isNextAvailable = isUrlPathValid && isPriceValid && isAmountValid && isUnitNameValid;
    return (
      <this.Step {...props} nextAvailable={isNextAvailable} stepName='Funding' >
        <Row>
          <Col lg={5} xs={12}>
            <Text din bold invalid={!isUrlPathValid} size='small'>Project ID</Text>
            <Input value={urlPath} onChange={e => this.onInputChange('urlPath', e)} />
          </Col>
          <Col lg={5} xs={12}>
            <Text din bold invalid={!isUnitNameValid} size='small'>Unit Name</Text>
            <Input value={unitName} onChange={e => this.onInputChange('unitName', e)} />
          </Col>
        </Row>
        <Row className='mt-2 mb-2'>
          <Col lg={5} xs={12}>
            <Text din bold invalid={!isPriceValid} size='small'>Price</Text>
            <Input value={unitPrice} onChange={e => this.onInputChange('unitPrice', e)} />
          </Col>
          <Col lg={5} xs={12}>
            <Text din bold invalid={!isAmountValid} size='small'>Units To Issue</Text>
            <Input value={unitAmount} onChange={e => this.onInputChange('unitAmount', e)} />
          </Col>
        </Row>
      </this.Step>
    )
  }

  renderStepThree = props => {
    const { images, avatar } = this.state;
    const isAvatarValid = Boolean(avatar);
    const isImageValid = Boolean(images) && images?.length > 0;
    const isNextAvailable = isAvatarValid && isImageValid;
    return (
      <this.Step {...props} nextAvailable={isNextAvailable} stepName='Graphics' >
        <Text din bold invalid={!isImageValid} size='small'>Images</Text>
        <FileZone
          ref='images' multiple clickable
          accepts={['image/png', 'image/jpg', 'image/jpeg']}
          className='images-container' onChange={this.onUpload}
          maxFiles={3} maxFileSize={10000000} minFileSize={0}
        >
          {
            this.state.images && this.state.images.map((image, idx) => {
              return (
                <div key={idx} style={{ position: 'relative', marginRight: 20 }}>
                  <img alt='icon' className='image-in-container' src={(image.preview && image.preview.url) || getImage(image)} />
                  <Badge
                    id={image.id}
                    pill theme="danger" className='remove-button'
                    onClick={() => this.removeImage(image)}
                  >
                    X
              </Badge>
                </div>
              )
            })
          }
        </FileZone>
        <Text din bold invalid={!isAvatarValid} size='small'>Avatar</Text>
        <FileZone
          ref='images' clickable
          accepts={['image/png', 'image/jpg', 'image/jpeg']}
          style={{ height: 100 }} onChange={this.onUploadAvatar}
          maxFiles={1} maxFileSize={10000000} minFileSize={0}
        >
          {
            this.state.avatar &&
            <img alt='icon' src={(this.state.avatar.preview && this.state.avatar.preview.url)
              ||
              getImage(this.state.avatar)} className='avatar-preview'
            />

          }
        </FileZone>
      </this.Step>
    )
  }

  render() {
    return (
      <MarginBox>
        <StepWizard>
          {this.renderStepOne()}
          {this.renderStepTwo()}
          {!this.props.editContent && this.renderStepThree()}
        </StepWizard>
      </MarginBox>
    )
  }
}

