import React from 'react';
import { Col } from 'shards-react';
import moment from 'moment';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './styles.scss';

export function EarthTimeline({ createdAt }) {
  return (
    <div className='row justify-content-center'>
      <Col>
        <VerticalTimeline>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: 'rgb(33, 150, 243)', color: '#000' }}
            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
            date={moment(createdAt).calendar()}
            iconStyle={{ background: 'rgb(33, 150, 243)', color: '#000' }}
            icon={null}
          >
            <h3 className="vertical-timeline-element-title">Project created</h3>
            <p>The project has been created along with its market.</p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            icon={null}
          >
            <h3 className="vertical-timeline-element-title">Project listed</h3>
            <p>The project is now listed.</p>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </Col>
    </div>
  )
}