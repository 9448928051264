import React from 'react';
import { TitledContainer, Text } from 'components';
import { connect } from 'react-redux';
import { setLoading } from 'store';
import { DropdownItem } from 'reactstrap';

const mapStateToProps = state => ({ role: state.root.user.role });
const mapDispatchToProps = dispatch => ({ setLoading: state => dispatch(setLoading(state)) });
export default connect(mapStateToProps, mapDispatchToProps)(props => {
  const onLogOut = () => {
    sessionStorage.clear();
    props.setLoading(true);
  };

  const navigate = (e, where) => {
    e.preventDefault();
    props.history.push(where);
  }

  return (
    <TitledContainer screen fluid title='Profile'>
      <DropdownItem className='mb-2 p-0' onClick={e => navigate(e, '/profile')}>
        <i className="material-icons my-auto mr-2">account_circle</i>
        <Text din bold size='large'>User details</Text>
      </DropdownItem>
      {
        props.role === 'admin' &&
        <DropdownItem className='mb-2 p-0' onClick={e => navigate(e, '/admin')}>
          <i className="material-icons my-auto mr-2">supervisor_account</i>
          <Text din bold size='large'>Admin settings</Text>
        </DropdownItem>
      }
      <DropdownItem className='mb-2 p-0'>
        <i className="material-icons my-auto mr-2">money</i>
        <Text din bold size='large'>Currency</Text>
      </DropdownItem>
      <DropdownItem className='mb-2 p-0' onClick={e => navigate(e, '/settings/security')}>
        <i className="material-icons my-auto mr-2">security</i>
        <Text din bold size='large'>Security</Text>
      </DropdownItem>
      <DropdownItem className='mb-2 p-0' onClick={e => navigate(e, '/settings')}>
        <i className="material-icons my-auto mr-2">build</i>
        <Text din bold size='large'>Settings</Text>
      </DropdownItem>
      <DropdownItem className='mb-2 p-0' divider />
      <DropdownItem className='mb-2 p-0' onClick={onLogOut}>
        <i className="material-icons my-auto mr-2">power_settings_new</i>
        <Text din bold size='large'>Log out</Text>
      </DropdownItem>
    </TitledContainer>
  )
})
