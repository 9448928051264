import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setLoading, updateUser } from 'store';
import { users } from 'controllers/requests';
import { toast } from 'react-toastify';
import { EarthSignIn } from './EarthSignIn';
import { EarthSignUp } from './EarthSignUp';

class Auth extends Component {

  state = { tab: 'signin', kyc: false, active: null, loggedIn: false };

  onSignUpSubmit = async (earthName, email, password) => {
    if (await users.signUp(earthName, email, password)) {
      toast.success('Account created!');
      this.setState({ tab: 'signin' });
    } else {
      toast.error('Failed to sign up!');
    }
  }

  onSignInSubmit = result => result && this.setState({ ...result });
  onKycSkip = () => this.props.setLoading(true);
  onKycFill = (POI, POR) => this.props.setLoading(true);
  swapTab = tab => !this.state.loggedIn && this.setState({ tab });

  renderInProcess = () => {
    const { loggedIn } = this.state;
    if (!loggedIn) return <EarthSignIn onSignInSubmit={this.onSignInSubmit} swapTab={() => this.setState({ tab: 'signup' })} />
    this.props.setLoading(true);
    return null;
  }

  render() {
    const { tab } = this.state;
    return (
      tab === 'signin' ?
        this.renderInProcess()
        :
        <EarthSignUp onSignUpSubmit={this.onSignUpSubmit} swapTab={() => this.setState({ tab: 'signin' })} />
    );
  }
}

Auth.propTypes = {
  history: PropTypes.object.isRequired,
};

const mapDispatchtoProps = dispatch => {
  return {
    setLoading: state => dispatch(setLoading(state)),
    updateUser: state => dispatch(updateUser(state)),
  };
}

export const EarthAuth = connect(null, mapDispatchtoProps)(Auth);