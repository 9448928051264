import React, { Component } from 'react';
import { Row } from 'shards-react';
import { Text } from '../../../components';
import './styles.scss';
import { markets } from '../../../controllers/requests';

export class EarthMarketList extends Component {
  constructor(props) {
    super(props);
    this.state = { markets: [] }
  }

  componentDidMount() {
    markets.getMarkets()
      .then(markets => this.setState({ markets }))
  }

  navigateMiddleware = id => {
    const { navigate } = this.props;
    if (navigate) {
      navigate(id)
    }
  }

  render() {
    const { markets } = this.state;
    const { selectedMarket } = this.props;
    return (
      <div>
        <ul className='market-list'>
          <li className='equityToken-element-no-avatar text-center mb-2'>
            <Row>
              <div className='col'>
                <Text size='xsmall' bold>
                  Token
                </Text>
              </div>
              <div className='col'>
                <Text size='xsmall' bold>
                  Price
                </Text>
              </div>
              <div className='col'>
                <Text size='xsmall' bold>
                  Change
                </Text>
              </div>
              <div className='col'>
                <Text size='xsmall' bold>
                  Status
                </Text>
              </div>
            </Row>
          </li>
          {
            markets.map(
              ({ unitName, marketStatus, initialPrice, lastPrice, unitPrice, id }) => (
                <li
                  className={`token-element-no-avatar ${selectedMarket === unitName ? 'active' : ''}`}
                  onClick={() => this.navigateMiddleware(unitName)}
                >
                  <Row className='text-center'>
                    <div className='col'>
                      <Text size='xsmall' bold>
                        {unitName}
                      </Text>
                    </div>
                    <div className='col'>
                      <Text size='xsmall' bold>
                        {unitPrice}
                      </Text>
                    </div>
                    <div className='col'>
                      <Text size='xsmall' bold>
                        {(((Number(lastPrice) / Number(initialPrice)) * 100) - 100) + '%'}
                      </Text>
                    </div>
                    <div className='col'>
                      <Text size='xsmall' bold>
                        {marketStatus ? 'Open' : 'Closed'}
                      </Text>
                    </div>
                  </Row>
                </li>
              )
            )
          }
        </ul>
      </div>
    );
  }
}