import axios from 'axios';
import { getToken } from './index';
import FormData from 'form-data';
import HTTPError from '../HTTPError';
import settings from 'settings';

export const projects = {
  createProject: async project => {
    delete project.publishing;
    const url = settings.API_URL + '/projects';
    const formData = new FormData();
    Object.entries(project).map(entry => {
      if (entry[0] !== 'images' && entry[0] !== 'avatar') {
        formData.set(
          entry[0],
          (typeof entry[1]) === 'object' ? JSON.stringify(entry[1]) : entry[1]
        );
      }
    });
    project.images.map((image, idx) => formData.append('images', image, image.name));
    formData.append('avatar', project.avatar, project.avatar.name);

    try {
      const res = await axios.post(url, formData, { headers: { 'content-type': 'multipart/form-data', Authorization: getToken() } });
      return res.data;
    } catch (err) {
      return false;
    }
  },
  updateProject: async (urlPath, project) => {
    const url = settings.API_URL + '/projects/' + urlPath;
    try {
      const res = await axios.patch(url, project, { headers: { Authorization: getToken() } });
      return res.data;
    } catch (err) {
      console.error(err);
      return false;
    }
  },
  deleteProject: async urlPath => {
    const url = settings.API_URL + `/projects/${urlPath}`;
    try {
      const res = await axios.delete(url, { headers: { Authorization: getToken() } });
      return res.data;
    } catch (err) {
      console.error(err);
      return false;
    }
  },
  getProjects: async () => {
    const url = settings.API_URL + `/projects`;
    try {
      const res = await axios.get(url, { headers: { Authorization: getToken() } });
      return res.data;
    } catch ({ response }) {
      if (response && response.data.error) return { error: HTTPError(response.data.error) }
      return { error: HTTPError() }
    }
  },
  getProjectById: async urlPath => {
    const url = settings.API_URL + `/projects/` + urlPath;
    try {
      const res = await axios.get(url, { headers: { Authorization: getToken() } });
      return res.data;
    } catch (err) {
      console.error(err);
      return false;
    }
  },
  buyShares: async (urlPath, amount, billing, useAccountFunds) => {
    const url = settings.API_URL + `/projects/` + urlPath + '/buy/' + amount;
    try {
      const res = await axios.post(url, { billing, useAccountFunds }, { headers: { Authorization: getToken() } });
      return res.data;
    } catch ({ response }) {
      if (response && response.data.error) return { error: HTTPError(response.data.error) }
      return { error: HTTPError() }
    }
  },
  updateShares: async (earthName, token, amount) => {
    const url = settings.API_URL + `/admin/users/shares`;
    try {
      const res = await axios.patch(url, { earthName, token, amount }, { headers: { Authorization: getToken() } });
      return res.data;
    } catch ({ response }) {
      if (response && response.data.error) return { error: HTTPError(response.data.error) }
      return { error: HTTPError() }
    }
  }
}