import React, { Component } from 'react';
import numeral from 'numeral';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { users } from 'controllers/requests';
import {
  Box,
  Cake,
  Text,
  Stats,
  Button,
  Dropdown,
  Activity,
  LineChart,
  CachedIcon,
  EarthModal,
  TitledContainer,
} from 'components';
import { Input } from 'components';
import { toast } from 'react-toastify';
import Checkout from 'components/Checkout/Checkout';
import QrReader from 'react-qr-reader';
import QRCode from 'react-qr-code';

const defaultStats = [
  { value: 33300, label: 'Balance' },
  { value: 24200, label: 'Principal', icon: {} },
  { value: 9100, label: 'Interest' },
  { value: 0.06, label: 'APR (USD)', type: 'percentage' },
]

const defaultImpact = {
  trees: 240000,
  carbon: 240000
}

const defaultChart = [
  { label: 'Cash account', value: 5000 },
  { label: 'Invested', value: 10000 },
]

const defaultLineChart =
{
  "1": [
    { label: 'asd', data: [0, 1, 2, 3, 4, 5, 2, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29] },
    { label: 'asda', data: [0, 1, 2, 3, 4, 5, 6, 14, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29] },
    { label: 'asdar', data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29] }
  ],
  "10": [
    { label: 'Principal', data: [200, 2600, 5000, 7400, 9800, 12200, 14600, 17000, 19400, 21800, 24200] },
    { label: 'Total balance', data: [200, 2691, 5337, 8145, 11127, 14293, 17654, 21222, 25011, 29033, 33303] },
    { label: 'Interest', data: [0, 91, 245, 408, 581, 765, 961, 1168, 1388, 1622, 1870] }
  ]
};

const defaultGrowthOptions = [
  { value: 10, label: '10 years' },
]


class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      equityTokens: [], rewardsTokens: [], activity: [], finalPrice: 0, stats: defaultStats,
      showModal: false, projects: [], loading: true, chartPeriod: 10, topUpAmount: 0,
      issueCardModal: false, checkoutModal: false, cardName: '', card: this.props.card
    }
  }

  async componentDidMount() {
    const { history: { push } } = this.props;
    users.getActivity().then(activity => this.setState({ activity }));
    users.getBankDetails().then(details => {
      const toPutStats = defaultStats;
      const account = details.accounts?.[0];
      if (account?.balance) {
        toPutStats[1] = { value: account.balance, label: 'Balance', icon:{ image: account.images.icon, label: account.name} }
        this.setState({ stats: toPutStats })
      }
      this.setState({ loading: false })

    })
    //request.getProjects().then(({ projects }) => this.setState({ projects, loadingProjects: false }));
    //balances.getBalances().then(equityTokens => this.setState({ loadingEquity: false, equityTokens }))
  }

  handleScan = data => {
    if (data) {
      this.setState({
        result: data
      })
    }
  }
  handleError = err => {
    console.error(err)
  }

  issueCard = async () => {
    this.setState({ loadingIssuance: true });
    const res = await users.issueCard(this.state.cardName);
    if (!res.error) {
      this.setState({ loadingIssuance: false, issueCardModal: false });
      toast.success('Card created');
      window.location.reload()
    }
  }

  createReceive = () => {
    this.setState({ loadingReceive: true });
    setTimeout(() => {
      this.setState({ loadingReceive: false, showQrReceive: true })
    }, 2000)
  }

  onPurchase = billingInfo => users.topUpCard(this.state.finalPrice, billingInfo);
  onPurchaseComplete = result => result && this.setState({ checkoutModal: false, card: { ...this.state.card, balance: Number(this.state.card.balance) + Number(this.state.finalPrice) } });

  renderDebitCardContent = () => (
    <Box center middle fluid>
      <Row>
        <Col>
          <Button style={{ width: 130 }} color='secondary' disabled={!this.state.card.balance} label='send' onClick={() => this.setState({ transferModal: true })} />
        </Col>
        <Col>
          <Button style={{ width: 130 }} label='receive' onClick={() => this.setState({ receiveModal: true })} />
        </Col>
      </Row>
    </Box>
  )

  handleScan = (value) => {
    let json = null
    try {
      json = JSON.parse(value);
    }
    catch (err) {

    }
    if (typeof json === 'object') this.setState({ transaction: json })
  }

  transfer = async () => {
    this.setState({ loadingTransfer: true });
    const res = await users.transferFunds(this.state.transaction.userId, this.state.transaction.amount);
    if (!res.error) {
      toast.success('Payment sent')
      this.setState({ transaction: null, transferModal: false, card: { ...this.state.card, balance: Number(this.state.card.balance) - Number(this.state.transaction.amount) } });
    } else {
      toast.error('Error');
    }
    this.setState({ loadingTransfer: false })
  }

  handleError = v => {

  }

  render() {
    const { chartPeriod, activity, loading } = this.state;
    return (
      <TitledContainer
        screen title='Dashboard'
        loading={loading}
        renderRightTitle={() => (
          <Button
            className='my-auto'
            onClick={() => this.props.history.push('/account/link')}
            label='Link'
          />
        )}
      >
        <Row style={{ marginBottom: 20 }}>
          {
            this.state.card ?
              <>
                <Col className='p-2'>
                  <TitledContainer backgroundColor box title='Debit Card' renderRightTitle={() => <Button className='my-auto' label='Top Up' onClick={() => this.setState({ checkoutModal: true })} />}>
                    <Box center middle fluid>
                      <img height={200} src={require('assets/images/earthbanc-card.jpg')} />
                    </Box>
                  </TitledContainer>
                </Col>
                <Col className='p-2'>
                  <TitledContainer backgroundColor fluid box title={'Card Balance: ' + numeral(this.state.card.balance || 0).format('$0,00')}>
                    {this.renderDebitCardContent()}
                  </TitledContainer>
                </Col>
              </>
              :
              <TitledContainer backgroundColor box fluid title='Debit Card' renderRightTitle={() => <Button className='my-auto' label='Issue Card' onClick={() => this.setState({ issueCardModal: true })} />}>
                <Box fluid middle center>
                  <i className='material-icons' style={{ fontSize: 35 }}>credit_card</i>
                  <Text center din bold dark>You don't have a card yet, let's issue one within seconds!</Text>
                </Box>
              </TitledContainer>
          }
        </Row>
        <Stats values={defaultStats} />
        <Row style={{ marginTop: 20 }}>
          <Col className='p-2'>
            <TitledContainer backgroundColor box fluid title='Growth'
              renderRightTitle={() => (
                <Dropdown
                  className='my-auto'
                  selected={chartPeriod}
                  onChange={value => this.setState({ chartPeriod: value })}
                  options={defaultGrowthOptions}
                />
              )}
            >
              <Box fluid middle center>
                <LineChart metrics={defaultLineChart[chartPeriod.toString()]} />
              </Box>
            </TitledContainer>
          </Col>
          <Col className='p-2'>
            <TitledContainer backgroundColor box fluid title='Portfolio'>
              <Box fluid center middle>
                <Cake values={defaultChart} />
              </Box>
            </TitledContainer>
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Col className='p-2' lg={6} xs={12}>
            <TitledContainer backgroundColor box fluid title='Impact' renderRightTitle={() => <Button className='my-auto' small color='secondary' label='See all' />}>
              <Box fluid middle>
                <Row className='mb-2 justify-content-center'>
                  <Col lg={4} align='center'>
                    <CachedIcon name='trees' />
                    <Text din dark size='large'>{numeral(defaultImpact.trees).format('0,0')}</Text>
                    <Text style={{ marginTop: -10 }}>Trees planted</Text>
                  </Col>
                  <Col lg={4} align='center'>
                    <CachedIcon name='cloud' />
                    <Text din dark size='large'>{numeral(defaultImpact.carbon).format('0,0')}</Text>
                    <Text style={{ marginTop: -10 }}>Carbon tonnes captured</Text>
                  </Col>
                </Row>
                <Text
                  style={{ paddingLeft: 30, paddingRight: 30 }}
                  center din dark size='xsmall'
                >
                  Each tree is expected to draw down approximately 1 tonne of carbon over its life cycle.
                </Text>
              </Box>
            </TitledContainer>
          </Col>
          <Col className='p-2' lg={6} xs={12}>
            <TitledContainer backgroundColor box fluid title='Activity'>
              <Activity activity={activity} />
            </TitledContainer>
          </Col>
        </Row>

        <EarthModal
          onClose={() => this.setState({ transferModal: false })}
          show={this.state.transferModal}
          style={{ textAlign: 'center' }}
        >
          {
            this.state.transaction ?
              <Text center din bold dark>You are about to send ${this.state.transaction.amount}</Text>
              :
              <>
                <QrReader
                  delay={300}
                  onError={this.handleError}
                  onScan={this.handleScan}
                  style={{ width: '100%', marginBottom: 20 }}
                />
                <Text center bold din dark>Camera access might misfunction in some browsers</Text>
              </>
          }
          <Button
            className='w-100'
            label='Send'
            disabled={!this.state.transaction}
            onClick={this.transfer}
            loading={this.state.loadingTransfer}
          />
        </EarthModal>

        <EarthModal
          onClose={() => this.setState({ receiveModal: false, receiveAmount: 0, showQrReceive: false })}
          show={this.state.receiveModal}
          style={{ textAlign: 'center' }}
        >
          {
            this.state.showQrReceive ?
              <>
                <Text din dark bold center style={{ marginBottom: 20 }}>Ask the payer to scan this QR</Text>
                <div style={{ textAlign: 'center', marginBottom: 20 }}>
                  <QRCode value={JSON.stringify({ amount: this.state.receiveAmount, userId: this.props.userId })} />
                </div>
                <Button
                  className='w-100'
                  label='Done'
                  onClick={() => this.setState({ receiveModal: false, receiveAmount: 0, showQrReceive: false })}
                />

              </>
              :
              <>
                <Text din dark bold center style={{ marginBottom: 20 }}>Receive</Text>
                <Input
                  label='Amount'
                  onChange={({ target: { value } }) => this.setState({ receiveAmount: value })}
                  style={{ marginBottom: 20 }}
                />
                <Button
                  className='w-100'
                  label='Create receipt'
                  disabled={!this.state.receiveAmount}
                  onClick={this.createReceive}
                  loading={this.state.loadingReceive}
                />
              </>
          }
        </EarthModal>

        <EarthModal
          onClose={() => this.setState({ issueCardModal: false })}
          show={this.state.issueCardModal}
          style={{ textAlign: 'center' }}
        >
          <Text din dark bold center style={{ marginBottom: 20 }}>Issue your card</Text>
          <Input
            label='Full Name'
            onChange={({ target: { value } }) => this.setState({ cardName: value })}
            style={{ marginBottom: 20 }}
          />
          <Button
            className='w-100'
            label='Issue'
            disabled={!this.state.cardName}
            onClick={this.issueCard}
            loading={this.state.loadingIssuance}
          />
          <Text center size='small'>We are working to provide issue physical cards, stay tuned</Text>
        </EarthModal>
        <EarthModal
          onClose={() => this.setState({ checkoutModal: false })}
          show={this.state.checkoutModal}
          style={{ textAlign: 'center' }}
        >
          <Input
            style={{ marginBottom: 10 }}
            label='Amount'
            onChange={({ target: { value } }) => this.setState({ finalPrice: value })}
          />
          <Checkout
            onPurchase={this.onPurchase}
            onPurchaseComplete={this.onPurchaseComplete}
            finalPrice={this.state.finalPrice}
            label={`You are adding ${this.state.finalPrice} for ${this.state.finalPrice} USD`}
          />
        </EarthModal>
      </TitledContainer>
    )
  }
}

const mapStatetoProps = state => ({ ...state.root.user });
export default connect(mapStatetoProps)(Account);