import React from 'react';
import settings from 'settings';
import { mergeStyles } from 'utils';

export const Logo = ({ width = 30, height = 30, light, word, wrap }) => (
  <div style={mergeStyles([
    !wrap && { display: 'flex' },
    wrap && { textAlign: 'center' },
    { alignItems: 'center' }
  ])}>
    {

      word ?
        <img
          style={{ height, width, resize: 'contain' }}
          src={require(`assets/icons/${light ? 'logo-white.svg' : 'logo.svg'}`)} alt='logo'
        />
        :
        <img
          style={{ height, width, resize: 'contain' }}
          src={light ? settings.LIGHT_LOGO : settings.DARK_LOGO} alt='logo'
        />
    }
  </div>
)