import axios from 'axios';
import { getToken } from './index';
import settings from 'settings';

export const alerts = {
  getAlerts: async () => {
    const url = settings.API_URL + '/feed'
    try {
      const res = await axios.get(url, { headers: { Authorization: getToken() } });
      return res.data;
    } catch (err) {
      return false;
    }
  },
  newAlert: async (location, message) => {
    const url = settings.API_URL + '/feed'
    try {
      const body = {
        label: message,
        location
      }
      const res = await axios.post(url, body, { headers: { Authorization: getToken() } });
      return res.data;
    } catch (err) {
      return false;
    }
  },
  removeAlert: async alertId => {
    const url = settings.API_URL + '/feed/' + alertId
    try {
      const res = await axios.delete(url, { headers: { Authorization: getToken() } });
      return res.data;
    } catch (err) {
      return false;
    }
  }
}
