import React, { useEffect } from 'react';
import { Spinner } from 'components';
import settings from 'settings';

export const Callback = props => {

  useEffect(() => {
    window.opener.postMessage(JSON.stringify(props));
  }, [])

  return (
    <div className='row justify-content-center' style={{ height: '100vh', overflow: 'hidden' }}>
      <div className='col-10 col-md-6 col-xl-4 my-auto text-center fade-in'>
        <img
          src={settings.LIGHT_LOGO}
          className='mb-4'
          alt='logo'
          width={200}
        />
        <Spinner />
      </div>
    </div>
  )
}