import axios from 'axios';
import { getToken } from './index';
import HTTPError from '../HTTPError';
import settings from 'settings';

export const users = {
  signIn: async (username, password) => {
    const url = settings.API_URL + '/user/signin';
    const isEmail = username.includes('@') && username.includes('.');
    const body = {
      [isEmail ? 'email' : 'earthName']: username,
      password,
    };

    try {
      const res = await axios.post(url, body);
      return res.data;
    } catch (error) {
      return false;
    }
  },
  signUp: async (earthName, email, password) => {
    const url = settings.API_URL + '/user/signup';
    const body = {
      earthName,
      password,
      email,
    };

    try {
      await axios.post(url, body);
      return true;
    } catch (error) {
      return false;
    }
  },
  resendActivationEmail: async () => {
    const url = settings.API_URL + '/user/activate';
    try {
      const res = await axios.patch(url, { headers: { Authorization: getToken() } });
      return res.data;
    } catch (err) {
      return false;
    }
  },
  getSelf: async () => {
    const url = settings.API_URL + '/user';
    try {
      const res = await axios.get(url, { headers: { Authorization: getToken() } });
      return res.data;
    } catch (err) {
      console.error(err);
      return false;
    }
  },
  getActivity: async (type) => {
    const url = `${settings.API_URL}/user/activity${type ? '/' + type : ''}`;
    try {
      const res = await axios.get(url, { headers: { Authorization: getToken() } });
      return res.data;
    } catch ({ response }) {
      if (response && response.data.error) return { error: HTTPError(response.data.error) }
      return { error: HTTPError() }
    }
  },
  getMetrics: async metric => {
    const url = `${settings.API_URL}/user/metrics${metric ? '/' + metric : ''}`;
    try {
      const res = await axios.get(url, { headers: { Authorization: getToken() } });
      return res.data;
    } catch ({ response }) {
      if (response && response.data.error) return { error: HTTPError(response.data.error) }
      return { error: HTTPError() }
    }
  },
  getSessions: async () => {
    const url = `${settings.API_URL}/user/sessions`;
    try {
      const res = await axios.get(url, { headers: { Authorization: getToken() } });
      return res.data;
    } catch ({ response }) {
      if (response && response.data.error) return { error: HTTPError(response.data.error) }
      return { error: HTTPError() }
    }
  },
  expireSession: async id => {
    const url = `${settings.API_URL}/user/sessions/${id}`;
    try {
      const res = await axios.delete(url, { headers: { Authorization: getToken() } });
      return res.data;
    } catch ({ response }) {
      if (response && response.data.error) return { error: HTTPError(response.data.error) }
      return { error: HTTPError() }
    }
  },
  getUserByName: async name => {
    const url = `${settings.API_URL}/user/${name}`;
    try {
      const res = await axios.get(url, { headers: { Authorization: getToken() } });
      return res.data;
    } catch ({ response }) {
      if (response && response.data.error) return { error: HTTPError(response.data.error) }
      return { error: HTTPError() }
    }
  },
  transferFunds: async (userIdTo, amount) => {
    const url = `${settings.API_URL}/user/card/transfer/${userIdTo}/${amount}`;
    try {
      const res = await axios.post(url, null, { headers: { Authorization: getToken() } });
      return res.data;
    } catch ({ response }) {
      if (response && response.data.error) return { error: HTTPError(response.data.error) }
      return { error: HTTPError() }
    }
  },
  issueCard: async (fullName) => {
    const url = `${settings.API_URL}/user/card/`;
    try {
      const res = await axios.post(url, { fullName }, { headers: { Authorization: getToken() } });
      return res.data;
    } catch ({ response }) {
      if (response && response.data.error) return { error: HTTPError(response.data.error) }
      return { error: HTTPError() }
    }
  },
  topUpCard: async (amount, billing) => {
    const url = settings.API_URL + `/user/card/money/${amount}`;
    try {
      const res = await axios.post(url, { billing }, { headers: { Authorization: getToken() } });
      return res.data;
    } catch ({ response }) {
      if (response && response.data.error) return { error: HTTPError(response.data.error) }
      return { error: HTTPError() }
    }
  },
  linkBankAccount: async params => {
    const url = `${settings.API_URL}/user/bank-details`;
    try {
      const res = await axios.post(url, params, { headers: { Authorization: getToken() } });
      return res.data;
    } catch ({ response }) {
      if (response && response.data.error) return { error: HTTPError(response.data.error) }
      return { error: HTTPError() }
    }
  },
  getBankDetails: async () => {
    const url = `${settings.API_URL}/user/bank-details`;
    try {
      const res = await axios.get(url, { headers: { Authorization: getToken() } });
      return res.data;
    } catch ({ response }) {
      if (response && response.data.error) return { error: HTTPError(response.data.error) }
      return { error: HTTPError() }
    }
  }

}
