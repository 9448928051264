import React, { useState, useEffect, useCallback } from "react";
import { EarthActivityWithButtons, Spinner, TitledContainer, MarginBox } from 'components';
import { Container } from "shards-react";
import { users } from 'controllers/requests';
import { toast } from "react-toastify";
import moment from "moment";

export default () => {
  const [loading, setLoading] = useState(false);
  const [activities, setActivities] = useState([]);
  const [removing, setRemoving] = useState(false);

  const refresh = useCallback(async () => {
    setLoading(true);
    const res = await users.getSessions();
    if (res.error) return;
    const sanitizedActivities = res.map((activity, index) => ({
      id: { index, token: activity.token },
      title: activity.sessionAgent,
      description: `Expires ${moment(activity.expires).calendar()}`,
      date: `Created ${moment(activity.created).calendar()}`
    }))
    setActivities(sanitizedActivities);
    setLoading(false);
  }, [])


  const onExpireSession = async id => {
    setRemoving(true);
    const res = await users.expireSession(id.token)
    await refresh();
    setRemoving(false);
    if (!res.error) toast.success('Removed session');
    else toast.error(res.error);
  }

  const renderSecurity = () => {
    return (
      <TitledContainer screen fluid title={'Active sessions'}>
        <MarginBox>
          {
            loading ?
              <Spinner />
              :
              <EarthActivityWithButtons
                disabled={removing}
                onRemove={onExpireSession}
                activities={activities}
              />
          }
        </MarginBox>
      </TitledContainer>
    )
  }

  useEffect(() => {
    refresh();
  }, [refresh]);

  return (
    <Container fluid className="main-content-container px-4">
      {renderSecurity()}
    </Container>
  );
}