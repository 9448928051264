import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './styles.scss';

export const EarthCard = ({ children, hover, className, style, ...rest }) => {
  return <div className={cn('EarthCard', className, hover ? 'EarthCard_hover' : '')} style={style} {...rest}>{children}</div>;
};

EarthCard.defaultProps = {
  children: null,
  className: null,
};

EarthCard.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  type: PropTypes.oneOf(['light', 'dark']),
  className: PropTypes.string,
};
