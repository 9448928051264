const validator = require('validator');

export function isAlpha(sample, allowSpaces) {
  if (!sample) return false;
  if(allowSpaces) return validator.isAlpha(sample.replace(/ /g, ''));
  else return validator.isAlpha(sample);
}

export function isAlphanumeric(sample, allowSpaces) {
  if (!sample) return false;
  if(allowSpaces) return validator.isAlphanumeric(sample.replace(/ /g, ''));
  else return validator.isAlphanumeric(sample);
}

export function isNumeric(sample) {
  if (!sample && sample !== 0) return false;
  return validator.isNumeric(sample + '');
}

export function isEmail(sample) {
  if (!sample) return false;
  return validator.isEmail(sample);
}

export function isLength(sample, min, max) {
  if (!sample) return false;
  return validator.isLength(sample, min, max);
}

export function isCreditCard(sample) {
  if(!sample) return false;
  const sanitized = sample.replace(/ /g, '');
  if(validator.isCreditCard(sanitized + '')) return sanitized;
  else return false;
}

export function isValidExpiration(sample) {
  if(!sample) return false;
  const tokenizedExpiry = sample.replace(/ /g, '').split('/');
  const exp_year = Number('20' + tokenizedExpiry[1]);
  const exp_month = Number(tokenizedExpiry[0]);

  const expiryDate = new Date(exp_year, exp_month - 1);
  const todayDate = (new Date(Date.now()));
  if (expiryDate >= todayDate) return { exp_month, exp_year }
  else return false;
}

export function isValidCvc(sample) {
  if(!sample) return false;
  const isValid = validator.isNumeric(sample + '') && (sample + '').length >= 3;
  return isValid;
}