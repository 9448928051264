import React from "react";
import { ProjectEditor, TitledContainer } from 'components'
import { projects } from 'controllers/requests';
import { toast } from "react-toastify";

const EarthProjectCreate = ({ history }) => (
  <TitledContainer screen fluid title='Create Project'>
    <ProjectEditor onSubmit={async project => {
      const res = await projects.createProject(project);
      if (res) {
        toast.success('Project created');
        history.push('/admin');
      } else toast.error('Error while creating project');
    }} />
  </TitledContainer>
);

export default EarthProjectCreate;
