import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setLock } from 'store';
import { NavLink } from 'react-router-dom';
import './styles.scss';

class Sidebar extends Component {
  onLock = event => {
    event.preventDefault();
    this.props.setLock(true);
  }

  setActiveTab = tab => {
    this.setState({ tab });
  }

  render() {
    const { pathname } = this.props.location
    const { role } = this.props;

    return (
      <div
        className={cn([cn({
          EarthSidebar: this.props.toggleShow,
          EarthSidebar_show: this.props.toggleShow,
        }), 'left', 'side-menu'])}
        onClick={this.props.onClickMenuIcon}
      >
        <div className="" id="remove-scroll">
          <div id="sidebar-menu">
            <ul className="metismenu" id="side-menu">
              <li className={pathname === '/dashboard' ? 'active' : undefined}>
                <NavLink activeClassName='active-item' to="/dashboard">
                  <i className="material-icons">dashboard</i>
                  <span> Dashboard </span>
                </NavLink>
              </li>
              <li className={pathname === '/impact' ? 'active' : undefined}>
                <NavLink activeClassName='active-item' to="/impact">
                  <i className="material-icons">show_chart</i>
                  <span> Impact </span>
                </NavLink>
              </li>
              <li className={pathname === '/rewards' ? 'active' : undefined}>
                <NavLink activeClassName='active-item' to="/rewards">
                  <i className="material-icons">compare_arrows</i>
                  <span> Rewards </span>
                </NavLink>
              </li>
              <li className={pathname === '/projects' ? 'active' : undefined}>
                <NavLink activeClassName='active-item' to="/projects">
                  <i className="material-icons">wb_incandescent</i>
                  <span> Projects </span>
                </NavLink>
              </li>
              {
                role !== 'user' &&
                <li className={pathname === '/admin' ? 'active' : undefined}>
                  <NavLink activeClassName='active-item' to="/admin">
                    <i className="material-icons">supervisor_account</i>
                    <span> Admin </span>
                  </NavLink>
                </li>
              }
              <li>
                <NavLink activeClassName='active-item' to="lock" onClick={this.onLock}>
                  <i className="material-icons">lock</i>
                  <span> Lock </span>
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="clearfix" />
        </div>
      </div>
    );
  }
};

Sidebar.propTypes = {
  toggleShow: PropTypes.bool,
};


const mapDispatchToProps = dispatch => ({
  setLock: state => dispatch(setLock(state))
});

const mapStatetoProps = state => {
  return {
    role: state.root.user.role,
  };
}

export const EarthSidebar = connect(mapStatetoProps, mapDispatchToProps)(Sidebar);
