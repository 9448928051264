import React from 'react'
import { withRouter } from 'react-router-dom';

export const BottomTabs = withRouter(props => {
  const navigate = (where) => {
    props.history.push(where)
  }
  const current = props.location.pathname;
  return (
    <div className='only-mobile bottom-fixed'>
        <div className='bottom-navbar h-100'>
          <div className={`text-center bottom-tab ${current === '/account' ? 'bottom-tab-active' : ''}`} onClick={() => navigate('/account')}>
            <i className='material-icons'>account_balance</i>
            <p className='small m-0'>Account</p>
          </div>
          <div className={`text-center bottom-tab ${current === '/impact' ? 'bottom-tab-active' : ''}`} onClick={() => navigate('/impact')}>
            <i className='material-icons'>eco</i>
            <p className='small m-0'>Earth Saver</p>
          </div>
          <div className={`text-center bottom-tab ${current === '/projects' ? 'bottom-tab-active' : ''}`} onClick={() => navigate('/projects')}>
            <i className='material-icons'>assessment</i>
            <p className='small m-0'>Projects</p>
          </div>
          <div className={`text-center bottom-tab ${current === '/notifications' ? 'bottom-tab-active' : ''}`} onClick={() => navigate('/notifications')}>
            <i className='material-icons'>notifications</i>
            <p className='small m-0'>Notifications</p>
          </div>
          <div className={`text-center bottom-tab ${current === '/profile-mobile' ? 'bottom-tab-active' : ''}`} onClick={() => navigate('/profile-mobile')}>
            <i className='material-icons'>account_circle</i>
            <p className='small m-0'>Profile</p>
          </div>
      </div>
    </div>
  )
})