/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import { Row, Col, } from "shards-react";
import { Link } from 'react-router-dom';
import { TitledContainer } from "components";
import { Text } from "components";
import { MarginBox } from "components";

const ProjectOptions = [
  {
    backgroundImage: require("../../../assets/images/projects.png"),
    category: 'project',
    categoryTheme: "info",
    title: "Create new project",
    body: "Create new projects.",
    pushTo: '/admin/project/create'
  },
  {
    backgroundImage: require("../../../assets/images/projects.png"),
    category: "project",
    categoryTheme: "info",
    title: "Edit existing projects",
    body: "Edit existing project.",
    pushTo: '/admin/project/edit'
  },
  {
    backgroundImage: require("../../../assets/images/proof-identity.png"),
    category: 'users',
    categoryTheme: "danger",
    title: "Manage users",
    body: "Manage access",
    pushTo: '/admin/users'
  },
  {
    backgroundImage: require("../../../assets/images/proof-identity.png"),
    category: 'users',
    categoryTheme: "danger",
    title: "Shares",
    body: "Modify user's shares",
    pushTo: '/admin/users/shares'
  },
]

class Admin extends React.Component {

  render() {

    return (
      <TitledContainer screen fluid title='Admin Settings'>
        <Row>
          {ProjectOptions.map((post, idx) => (
            <Col lg="4" sm="12" className="mb-4" key={idx}>
              <Link to={post.pushTo}>
                <MarginBox>
                  <TitledContainer
                    className='padding-container'
                    title={post.title}
                  >
                    <Text>{post.body}</Text>
                  </TitledContainer>
                </MarginBox>
              </Link>
            </Col>
          ))}
        </Row>
      </TitledContainer>
    );
  }
}

export default Admin;