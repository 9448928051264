import React, { Component } from 'react';
import { EarthTabItem, EarthTabs, Spinner, MarginBox, TitledContainer, Text } from '../../../components';
import { markets } from '../../../controllers/requests';
import './styles.scss';
import { toast } from 'react-toastify';
import { FormInput, Button } from 'shards-react';

export class BuySellForm extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, price: '', amount: '', tab: 'Buy' };
  }

  onSubmit = action => {
    const { market } = this.props;
    const { price, amount, tab } = this.state;
    markets.postOrder(market, tab, amount, price)
      .then(res => {
        if (res) toast.success('Order submited successfully')
        else toast.error('Error while submiting order')
      });
  }

  renderForm = ({ title }) => (
    <EarthTabItem
      title={title}
      onClick={() => this.setState({ tab: title, price: '', amount: '' })}
      isActive={this.state.tab === title}
    >
      <div className="form-group">
        <Text bold size='small'>Amount</Text>
        <FormInput
          onChange={({ target: { value } }) => this.setState({ amount: value })}
          value={this.state.amount} flex style={{ height: 25 }}
        />
      </div>
      <div className="form-group">
        <Text bold size='small'>Price</Text>
        <FormInput
          onChange={({ target: { value } }) => this.setState({ price: value })}
          value={this.state.price} flex style={{ height: 25 }}
        />
      </div>
      <div className="form-group">
        <Text bold size='small'>Total</Text>
        <FormInput
          disabled={true} style={{ height: 25 }}
          value={Number(this.state.price) * Number(this.state.amount)} flex
        />
      </div>
      <Button
        type="button"
        className='w-100'
        onClick={() => this.onSubmit(title)}
        disabled={!(this.state.price && this.state.amount && this.props.market)}
      >
        {
          this.state.loading ?
            <Spinner />
            :
            title

        }
      </Button>
    </EarthTabItem>
  )

  render() {
    return (
      <MarginBox fluid>
        <TitledContainer>
          <EarthTabs>
            {this.renderForm({ title: 'Buy' })}
            {this.renderForm({ title: 'Sell' })}
          </EarthTabs>
        </TitledContainer>
      </MarginBox>
    );
  }
}