import axios from 'axios';
import { getToken } from './index';
import settings from 'settings';

export const markets = {
  getMarkets: async () => {
    const url = settings.API_URL + '/markets';
    try {
      const res = await axios.get(url, { headers: { Authorization: getToken() } });
      return res.data;
    } catch (err) {
      console.error(err);
      return false;
    }
  },
  getMarketById: async market => {
    const url = settings.API_URL + '/markets/' + market;
    try {
      const res = await axios.get(url, { headers: { Authorization: getToken() } });
      return res.data;
    } catch (err) {
      console.error(err);
      return false;
    }
  },
  getMetricsById: async market => {
    const url = settings.API_URL + '/markets/' + market + '/metrics';
    try {
      const res = await axios.get(url, { headers: { Authorization: getToken() } });
      return res.data;
    } catch (err) {
      console.error(err);
      return false;
    }
  },
  getOrders: async (market, type) => {
    const url = settings.API_URL + '/markets/' + market + '/orders/' + type;
    try {
      const res = await axios.get(url, { headers: { Authorization: getToken() } });
      return res.data;
    } catch (err) {
      console.error(err);
      return false;
    }
  },
  getHistory: async market => {
    const url = settings.API_URL + '/markets/history';
    try {
      const res = await axios.get(url, { headers: { Authorization: getToken() } });
      return res.data;
    } catch (err) {
      console.error(err);
      return false;
    }
  },
  getHistoryByMarket: async market => {
    const url = settings.API_URL + '/markets/' + market + '/history';
    try {
      const res = await axios.get(url, { headers: { Authorization: getToken() } });
      return res.data;
    } catch (err) {
      console.error(err);
      return false;
    }
  },
  postOrder: async (market, type, amount, price) => {
    const url = settings.API_URL + '/markets/' + market + '/orders';
    const body = {
      type: type === 'Buy' ? 'buy' : 'sell',
      amount, price
    }

    try {
      const res = await axios.post(url, body, { headers: { Authorization: getToken() } });
      return res.data;
    } catch (err) {
      console.error(err);
      return false;
    }
  }
}
