/* eslint jsx-a11y/anchor-is-valid: 0 */
/* eslint jsx-a11y/anchor-has-content: 0 */
import React from "react";
import { Row, FormInput, FormCheckbox, Col, Badge } from "shards-react";
import {
  Text, EarthButton,
  EarthTabs, EarthTabItem, EarthModal, EarthTimeline, Spinner
} from 'components';
import { EarthQuestion } from "./EarthQuestion";
import ImageGallery from 'react-image-gallery';
import { connect } from 'react-redux'
import './styles.scss';
import { projects } from 'controllers/requests';
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.bubble.css';
import Checkout from "components/Checkout/Checkout";
import CommentBox from './CommentBox';
import { isNumeric } from "utils/validations";
import settings from "settings";
import { TitledContainer } from "components";
import { Progress } from "reactstrap";

class Projects extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shares: '',
      useAccountFunds: false,
      showModal: false,
      tab: 'story',
      loading: true,
      imagesUrl: [],
      avatarUrl: ''
    };
  }

  componentDidMount() {
    const { urlPath } = this.props.match.params;
    projects.getProjectById(urlPath)
      .then(project => {
        this.setState({ ...project, loading: false });
        const imagesUrl = project.images.map(imageId => ({ original: settings.API_URL + '/static/images/' + imageId }));
        const avatarUrl = settings.API_URL + '/static/images/' + project.avatar;
        this.setState({ imagesUrl, avatarUrl });
      })
  }

  onPurchase = billingInfo => projects.buyShares(this.state.urlPath, this.state.shares, billingInfo, this.state.useAccountFunds)
  onPurchaseComplete = result => result && this.setState({ showModal: false });
  onBuy = () => this.setState({ showModal: true })
  swapTab = tab => this.setState({ tab });
  onShareChange = event => this.setState({ shares: event.target.value });

  render() {
    const { shares, tab, loading, imagesUrl, showModal } = this.state;
    const project = this.state;
    const finalPrice = shares ? Number(shares) * Number(project.unitPrice) : '';
    return (
      loading ?
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          minHeight: '100vh'
        }}
        >
          <Spinner />
        </div>
        :
        <>
          <TitledContainer screen fluid title={project.name}>

            <Row className='mb-4'>
              <div className='col-12 col-lg-8'>
                <ImageGallery showFullscreenButton={false} showPlayButton={false} additionalClass='image-gallery-cropped' items={imagesUrl} />
              </div>
              <div className='col-12 col-lg-4' style={{ display: 'flex', flexDirection: 'column' }}>
                <Badge
                  pill
                  className={`card-post__category mb-4`}
                >
                  {project.badge}
                </Badge>
                <Text fontStyle={'light'} size='medium'>
                  {project.description}
                </Text>
                <div className='divider' />
                <Row>
                  <Col xs={10}>
                    <Text className='inline' size='small' center din>
                      State of this project
                    </Text>
                    <Text size={'small'} className='inline ml-2' center bold>
                      {'RAISING'}
                    </Text>
                  </Col>
                  <Col>
                    <Text size={'small'} className='inline' center din bold>
                      10%
                    </Text>
                  </Col>
                </Row>
                <Progress value={10} />
                <div className='divider mt-2 mb-2' />
                <div style={{ flex: 1, flexDirection: 'column', display: 'flex', justifyContent: 'end' }}>
                  <Text className='mb-1' fontStyle='bold'>What size of loan do you want to make?</Text>
                  <FormInput onChange={this.onShareChange} value={shares} placeholder='Units' />
                  <EarthButton
                    className='mt-2'
                    flex onClick={this.onBuy} disabled={!isNumeric(shares) || !project.marketStatus}
                  >
                    BUY
                  </EarthButton>
                  {!project.marketStatus && <Text dark bold din size='small'>Investments are not open yet on this project.</Text>}
                  <div className='row' style={{ justifyContent: 'space-between', margin: 'inherit' }}>
                    <Text className='inline' size='small' fontStyle='bold'>Total: ${finalPrice}</Text>
                    <FormCheckbox
                      className='inline'
                      onChange={() => this.setState({ useAccountFunds: !this.state.useAccountFunds })}
                      checked={this.state.useAccountFunds}
                    >
                      Use funds on my account
                    </FormCheckbox>
                  </div>
                </div>
              </div>
            </Row>
            <EarthTabs>
              <EarthTabItem
                onClick={() => this.swapTab('story')}
                icon=""
                title="Story"
                isActive={tab === 'story'}
              >
                <ReactQuill
                  value={project.description}
                  readOnly={true}
                  theme={"bubble"}
                />
              </EarthTabItem>
              <EarthTabItem
                onClick={() => this.swapTab('faq')}
                icon=""
                title="FAQ"
                isActive={tab === 'faq'}
              >
                {!project.faq.length && <CommentBox />}
                {project.faq.map(({ question, answer }) => <EarthQuestion question={question} answer={answer} />)}
              </EarthTabItem>
              <EarthTabItem
                onClick={() => this.swapTab('updates')}
                icon=""
                title="Updates"
                isActive={tab === 'updates'}
              >
                <EarthTimeline />
              </EarthTabItem>
              <EarthTabItem
                onClick={() => this.swapTab('comments')}
                icon=""
                title="Comments"
                isActive={tab === 'comments'}
              >
                <CommentBox />
              </EarthTabItem>
            </EarthTabs>
          </TitledContainer>
          <EarthModal
            onClose={() => this.setState({ showModal: false })}
            show={showModal}
            style={{ textAlign: 'center' }}
          >
            <Checkout
              onPurchase={this.onPurchase} onPurchaseComplete={this.onPurchaseComplete} finalPrice={finalPrice}
              label={`You are about to buy ${shares} units for ${finalPrice} USD`}
            />
          </EarthModal>
        </>
    );
  }
}


const mapStatetoProps = state => {
  return {
    ...state.root.user,
  };
}

export default connect(mapStatetoProps)(Projects);