import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Account from './Account';
import LinkAccount from './LinkAccount';
import EarthProjectCreate from './EarthProjectCreate/EarthProjectCreate';
import EarthProjectEdit from './EarthProjectCreate/EarthProjectEdit';
import EarthProjectsMarket from './EarthProjectsMarket/EarthProjectsMarket';
import EarthProjectView from './EarthProjectView/EarthProjectView';
import EarthProjects from './EarthProjects/EarthProjects';
import AlertsManagement from './AlertsManagement';
import EarthSettings from './EarthSettings/EarthSettings';
import EarthSecurity from './EarthSecurity/EarthSecurity';
import EarthProfile from './EarthProfile/EarthProfile';
import AdminUserShares from './Admin/AdminUserShares'
import ProfileMobile from './ProfileMobile';
import Notifications from './Notifications';
import { Callback } from './Callback';
import Admin from './Admin/Admin'
import './styles.scss';

export const EarthApp = props => {
  return (
    <Switch>
      <Route exact path="/account" render={props => <Account {...props} />} />
      <Route exact path="/account/link" render={props => <LinkAccount {...props} />} />
      <Route path="/account/link/callback/:provider" render={props => <Callback {...props} />}/>

      <Route path="/projects" exact component={EarthProjects} />
      <Route path="/projects/market" exact component={EarthProjectsMarket} />
      <Route path="/project/:urlPath" component={EarthProjectView} />

      <Route path="/admin" exact component={Admin} />
      <Route path="/admin/alerts" exact component={AlertsManagement} />
      <Route path="/admin/project/create" exact {...props} component={EarthProjectCreate} />
      <Route path="/admin/project/edit" exact {...props} component={EarthProjectEdit} />


      <Route path="/admin" exact component={Admin} />
      <Route path="/admin/users/shares" exact component={AdminUserShares} />
      <Route path="/admin/project/create" exact {...props} component={EarthProjectCreate} />
      <Route path="/admin/project/edit" exact {...props} component={EarthProjectEdit} />

      <Route path="/profile" component={EarthProfile} />
      <Route path="/profile-mobile" component={ProfileMobile} {...props} />
      <Route path="/notifications" component={Notifications} />

      <Route path="/settings" exact component={EarthSettings} />
      <Route path="/settings/security" exact component={EarthSecurity} />
      <Route component={() => <Redirect to={'/account'} />} />
    </Switch>
  );
};
