import React from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { utils } from '../../controllers/requests';

export class EarthAlertBox extends React.Component {
  state = { feed: {} }

  async componentDidMount() {
    const feed = await utils.getAlerts();
    const feedUpdate = {}
    if(feed) {
      for (let alert of feed) {
        if (!feedUpdate[alert.location]) feedUpdate[alert.location] = []
        feedUpdate[alert.location].push(alert);
      }
      this.setState({ feed: feedUpdate });
    }
  }

  render() {
    const { className, history: { location } } = this.props;
    const { feed } = this.state;
    const alertsToShow = feed[location.pathname] || [];

    return (
      <div className={cn(['EarthAlertBox', className])}>
        {
          alertsToShow.map((content, index) => (
            <div key={index} className="alertsucess">{content.label}</div>
          ))
        }
      </div>
    )
  }
}

EarthAlertBox.propTypes = {
  children: PropTypes.any,
  className: PropTypes.any,
};
