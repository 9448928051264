import React, { Component } from "react";
import { Button, ProjectEditor, EarthModal, Spinner, TitledContainer, Text, Input } from 'components'
import { projects } from '../../../controllers/requests';
import { toast } from 'react-toastify';

class EarthProjectEdit extends Component {
  state = { showModal: true, loading: false, urlPath: '', deleting: false, editContent: {} };

  onChangeUrlPath = ({ target: { value } }) => this.setState({ urlPath: value.toLowerCase() })

  onEdit = () => {
    const { urlPath } = this.state;
    this.setState({ loading: true });
    projects.getProjectById(urlPath).then(project => {
      if (project) this.setState({ editContent: project });
      else {
        toast.error('Could not fetch project')
        this.props.history.push('/admin')
      }
      this.setState({ showModal: false });
    });
  }

  onClose = () => this.props.history.push('/admin');

  render() {
    const { showModal, loading, urlPath, editContent } = this.state;
    const { history } = this.props;

    return (
      <TitledContainer
        renderRightTitle={() =>
          this.state.showModal ?
            null
            :
            <Button
              loading={this.state.deleting}
              className='my-auto' color='danger' label='delete'
              onClick={() => {
                this.setState({ deleting: true })
                projects.deleteProject(urlPath).then(res => {
                  if (res !== false) {
                    toast.success('Deleted project');
                    this.props.history.push('/admin');
                  } else {
                    toast.error('Could not delete project')
                    this.setState({ deleting: false })
                  }
                })
              }}
            />
        }
        screen fluid title='Edit Project'
      >
        {
          !showModal
          &&
          <ProjectEditor editContent={editContent}
            onSubmit={async project => {
              const res = await projects.updateProject(urlPath, project);
              if (res) {
                toast.success('Project created');
                history.push('/admin');
              } else toast.error('Error while creating project');
            }}
          />}
        <EarthModal
          onClose={this.onClose}
          show={showModal}
          style={{ textAlign: 'center' }}
        >
          <Text bold din dark center>
            Insert project ID
          </Text>
          <Input
            value={urlPath}
            onChange={this.onChangeUrlPath}
            className='mb-2'
          />
          {
            loading ?
              <Spinner />
              :
              <Button className="EarthKyc__modal_button centered text-bold mt-1 w-100"
                label='edit'
                onClick={this.onEdit}
              />
          }
        </EarthModal>
      </TitledContainer>
    )
  }
}

export default EarthProjectEdit;
