import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { EarthText } from '../EarthText/EarthText';
import FileZone from 'react-files';

export const EarthProof = ({ title, description, image, isChecked, onUpload }) => {
  return (
    <FileZone
      className='EarthProof'
      onChange={onUpload}
      accepts={['image/png','image/jpg','image/jpeg']}
      multiple
      maxFiles={1}
      maxFileSize={10000000}
      minFileSize={0}
      clickable
    >
      <img src={image} height="54" alt="earth-proof" className="EarthProof__image" />
      <div className="EarthProof__text_container">
        <EarthText className="EarthProof__text_title" fontStyle="bold">
          {title}
        </EarthText>
        <EarthText className="EarthProof__text_description" size="xsmall" fontStyle="light">
          {description}
        </EarthText>
      </div>
      <div className="EarthProof__status">
        {isChecked ? (
          <i className="material-icons icon p10">check_circle</i>
        ) : (
            <span className='EarthProof__instant'>
              Drop or upload
            </span>
          )}
      </div>
    </FileZone>
  );
};

EarthProof.defaultProps = {
  isChecked: false,
  className: null,
};

EarthProof.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.any.isRequired,
  isChecked: PropTypes.bool,
  className: PropTypes.string,
};
