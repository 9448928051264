import React from "react";
import PropTypes from "prop-types";
import { ListGroup, ListGroupItem, FormCheckbox } from "shards-react";

export const SidebarCategories = ({ title }) => (
  <ListGroup flush>
    <ListGroupItem className="px-3 pb-2">
      <FormCheckbox className="mb-1" value="uncategorized" defaultChecked>
        Uncategorized
      </FormCheckbox>
      <FormCheckbox className="mb-1" value="design" defaultChecked>
        Need technology
      </FormCheckbox>
      <FormCheckbox className="mb-1" value="development">
        Need development
      </FormCheckbox>
      <FormCheckbox className="mb-1" value="development">
        Need funds
      </FormCheckbox>
    </ListGroupItem>
  </ListGroup>
);

SidebarCategories.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

SidebarCategories.defaultProps = {
  title: "Categories"
};
