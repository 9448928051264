import moment from 'moment';
import settings from 'settings';

const diffTime = (from, to) => {
  const diff = moment(from).diff(to, 'days');
  return diff > 0 ? diff : 0;
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getCarbonByTime(investments, targetTime) {
  const investmentCarbon = investments.map(
    inv => {
      let previousTimestamp = 0;
      const investmentCarbons = []

      if (inv.changes.length === 1) {
        return inv.amount * inv.changes[0].newValue * diffTime(targetTime, inv.timestamp);
      }

      let ch;
      for (ch of inv.changes) {
        if (ch.timestamp < inv.timestamp)
          if (ch.timestamp > targetTime) {
            investmentCarbons.push(diffTime(targetTime, previousTimestamp) * ch.oldValue);
            break;
          } else {
            let isFirst = ch.timestamp < inv.timestamp;
            if (isFirst) investmentCarbons.push(diffTime(targetTime, inv.timestamp) * ch.newValue)
            else {
              if (targetTime > ch.timestamp) {
                investmentCarbons.push(diffTime(targetTime, ch.timestamp) * ch.newValue);
              } else {
                investmentCarbons.push(diffTime(ch.timestamp, previousTimestamp) * ch.newValue)
              }
            }
            previousTimestamp = ch.timestamp;
          }
      }
      const totalInvestmentCarbon = inv.amount * investmentCarbons.reduce((a, b) => a + b);
      return totalInvestmentCarbon;
    }
  )
  const totalCarbon = investmentCarbon.reduce((a, b) => a + b);
  return totalCarbon;
}

export function getInvestmentByTime(investments, targetTime) {
  let totalInvestment = 0;
  for (let inv of investments) {
    if (inv.timestamp > targetTime) break;
    else totalInvestment = totalInvestment + Number(inv.amount);
  }
  return totalInvestment;
}
export const getImage = imageId => `url('${settings.API_URL}/static/images/${imageId}')`;
export const mergeStyles = styles => styles.reduce((prev, curr) => ({ ...prev, ...curr }), {});
export const isJson = sample => {
  try {
    JSON.parse(sample)
    return true
  } catch(err) {
    return false
  }
}