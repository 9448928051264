import React from "react";
import PropTypes from "prop-types";
import Spinner from 'react-spinner-material';
import Chart from "utils/chart";

export class Cake extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  upgradeChart = () => {
    if (this.props.loading) return true;
    const { values } = this.props;
    const fullAmount = values.reduce((prev, cur) => prev + cur.value, 0);
    const labelWithPercentage = values.map(({ label, value }) => ({ label, percentage: (100 * value / fullAmount).toFixed(1) }))
    const chartData = {
      datasets: [
        {
          data: labelWithPercentage.map(({ percentage }) => percentage),
          backgroundColor: [
            '#004F5D',
            '#00A8B5'
          ]
        }
      ],
      labels: labelWithPercentage.map(({ label, percentage }) => `${label}: ${percentage}%`)
    }
    const chartConfig = {
      type: "doughnut",
      data: chartData,
      options: {
        legend: {
          position: 'right',
          align: 'start',
          labels: {
            fontColor: '#004F5D',
            fontFamily: 'DIN',
            fontSize: 14,
          }
        }
      }
    };

    new Chart(this.canvasRef.current, chartConfig);
  }

  componentDidMount() {
    this.upgradeChart();
  }

  componentDidUpdate(prevProps) {
    this.upgradeChart();
  }


  render() {
    return (
      this.props.loading ?
        <div style={{ width: 120, margin: 'auto' }}>
          <Spinner size={80} spinnerColor={"#FFF"} spinnerWidth={2} visible={true} />
        </div>
        :
        <div style={{ width: '99%' }}>
          <canvas height={100} ref={this.canvasRef} />
        </div>

    );
  }
}

Cake.propTypes = {
  chartConfig: PropTypes.object,
  chartOptions: PropTypes.object,
  chartData: PropTypes.object
};

Cake.defaultProps = {
  title: "Users by device",
  chartData: {
    datasets: [
      {
        hoverBorderColor: "#ffffff",
        data: [25, 25, 25, 25],
        backgroundColor: [
          "rgba(0,123,255,0.9)",
          "rgba(0,123,255,0.5)",
          "rgba(0,123,255,0.3)",
          "rgba(0,123,255,0.1)",
        ]
      }
    ],
    labels: ["KGT", "FCT", "CET", "EST"]
  }
};