import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './styles.scss';

export const EarthButton = ({
  size,
  disabled,
  children,
  flex,
  color,
  pill,
  outline,
  className,
  ...rest
}) => {
  const textStyles = cn({
    Button: true,
    btn: true,
    [`btn-${color}`]: !outline,
    'btn-block': flex,
    'btn-pill': pill,
    [`btn-outline-${color}`]: outline,
    'btn-sm': size === 'small',
    'btn-lg': size === 'large',
    Button_disabled: disabled,
  });

  return (
    <button className={cn(textStyles, className)} disabled={disabled} {...rest}>
      {children}
    </button>
  );
};

EarthButton.defaultProps = {
  size: 'medium',
  color: 'primary',
  className: null,
  disabled: false,
};

EarthButton.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  color: PropTypes.oneOf([
    'default',
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark',
  ]),
  flex: PropTypes.bool,
  pill: PropTypes.bool,
  outline: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.any,
};
