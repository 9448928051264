import React from "react";
import Chart from "utils/chart";
import numeral from 'numeral';

const lineColors = [
  { backgroundColor: ["rgb(0, 79, 93, 0.1)", "rgb(0, 79, 93, 0)"], borderColor: "#004F5D" },
  { backgroundColor: ["rgba(0,123,200, 0)", "rgba(0,123,255,0)"], borderColor: "rgb(0, 168, 181)" },
  { backgroundColor: ["rgba(0,123,105, 0.1)", "rgba(0,123,255,0)"], borderColor: "#99DCE1" },
  { backgroundColor: ["rgba(0,123,0, 0.1)", "rgba(0,123,255,0)"], borderColor: "rgba(0,123,255,1)" }
];

export class LineChart extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    this.generateChart()
  }

  componentDidUpdate({ metrics: prevMetrics }) {
    const { metrics } = this.props;
    if (prevMetrics === metrics) return true;
    this.generateChart()
  }

  generateChart = () => {
    const { metrics } = this.props;
    var ctx = document.getElementById('myChart').getContext("2d");

    const metricDatasets = metrics.map((metric, index) => {
      var gradientStroke = ctx.createLinearGradient(0, 0, 0, 200);
      gradientStroke.addColorStop(0, lineColors[index].backgroundColor[0]);
      gradientStroke.addColorStop(1, lineColors[index].backgroundColor[1]);
      return {
        label: metric.label,
        fill: "start",
        data: metric.data,
        backgroundColor: gradientStroke,
        borderColor: lineColors[index].borderColor,
        pointBackgroundColor: "#ffffff",
        pointHoverBackgroundColor: "rgb(0,123,255)",
        borderWidth: 2.5,
        pointRadius: 0,
        pointHoverRadius: 5
      }
    });

    const dayInMillis = 1000 * 60 * 60 * 24;
    const chartData = {
      labels: (new Array(11)).fill().map((value, index) => index),//moment(Date.now() - dayInMillis * (30 - index)).calendar()),
      datasets: metricDatasets
    }

    const chartOptions = {
      responsive: true,
      legend: { display: false },
      elements: {
        line: { tension: 0.3 },
        point: { radius: 10 }
      },
      scales: {
        xAxes: [
          {
            scaleLabel: {
              display: true,
            },
            gridLines: false,
            ticks: {
              maxRotation: 0,
              fontFamily: 'DIN',
              fontColor: '#004F5D',
              callback: (tick, index) => !(index % 2) ? `${tick}yr` : ''
            }
          }
        ],
        yAxes: [
          {
            gridLines: {
              display: false,
            },
            ticks: {
              min: 0,
              maxTicksLimit: 5,
              fontFamily: 'DIN',
              fontColor: '#004F5D',
              callback: tick => `${numeral(tick).format('0a')}`
            }
          }
        ]
      },
      hover: { mode: "nearest", intersect: false, },
      tooltips: {
        custom: false,
        mode: "nearest",
        intersect: false
      },
    };

    const BlogUsersOverview = new Chart(this.canvasRef.current, {
      type: "LineWithLine",
      data: chartData,
      options: chartOptions
    });

    if (!metrics.length) return BlogUsersOverview.render();

    const buoMeta = BlogUsersOverview.getDatasetMeta(0);
    buoMeta.data[0]._model.radius = 0;
    buoMeta.data[chartData.datasets[0].data.length - 1]._model.radius = 0;

    BlogUsersOverview.render();
  }

  render() {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }} className='w-100 h-100'>
        <canvas id='myChart' ref={this.canvasRef} />
      </div>
    );
  }
}
