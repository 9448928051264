import React from 'react';
import { mergeStyles } from 'utils';

const style = {
  backgroundColor: 'white',
  padding: 20
}

export const MarginBox = ({ children, fluid, ...rest }) => (
  <div
    {...rest}
    style={mergeStyles([
      style,
      fluid && { height: '100%' },
      rest.style
    ])}
  >
    {children}
  </div>
)