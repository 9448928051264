import React from 'react';
import { mergeStyles } from 'utils';

const sizes = {
  din: {
    large: 26,
    medium: 18,
    small: 14,
    xsmall: 10
  },
  apercu: {
    large: 26,
    medium: 14,
    small: 12,
    xsmall: 10,
  }
}

const buttonStyle = {
  cursor: 'pointer'
}

export const Text = ({ din, bold, dark, children, invalid, center, size = 'medium', style, className, onClick, ...rest }) => {
  return (
    <p
      {...rest}
      className={className}
      onClick={!invalid ? onClick : undefined}
      style={mergeStyles([
        invalid && { color: 'red' },
        center && { textAlign: 'center' },
        dark && { color: '#004F5D' },
        din && { fontFamily: 'DIN' },
        onClick && buttonStyle,
        {
          fontSize: sizes[din ? 'din' : 'apercu'][size],
          ...style
        }
      ])}
    >
      {children}
    </p >
  )
}