import React, { Component } from 'react';
import { Row } from 'shards-react';
import moment from 'moment';
import { Text } from 'components';
import { markets } from 'controllers/requests';
import './styles.scss';

export class EarthMarketHistory extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, history: [] };
  }

  componentDidMount() {
    const { market } = this.props;
    if (!market) this.pullHistory()
    else this.pullHistoryByMarket();
  }

  componentDidUpdate({ market: prevMarket }) {
    const { market } = this.props;
    if (market !== prevMarket) {
      if (!market) this.pullHistory()
      else this.pullHistoryByMarket();
    }
  }

  pullHistory = () => {
    this.setState({ loading: true });
    markets.getHistory()
      .then(history => this.setState({ history: history || [], loading: false }));
  }

  pullHistoryByMarket = () => {
    const { market } = this.props;
    this.setState({ loading: true });
    markets.getHistoryByMarket(market)
      .then(history => this.setState({ history: history || [], loading: false }));
  }

  render() {
    const { history } = this.state;
    return (
      <div className="text-container">
        <ul className='token-list scrollbar'>
          <li className='token-element-no-avatar'>
            <Row style={{ alignItems: 'center', flexWrap: 'no-wrap' }}>
              <div className='col'>
                <Text size='xsmall' bold>
                  Date
              </Text>
              </div>
              <div className='col'>
                <Text size='xsmall' bold>
                  Token
                </Text>
              </div>
              <div className='col'>
                <Text size='xsmall' bold>
                  Type
              </Text>
              </div>
              <div className='col'>
                <Text size='xsmall' bold>
                  Amount
              </Text>
              </div>
              <div className='col'>
                <Text size='xsmall' bold>
                  Price
              </Text>
              </div>
              <div className='col only-desktop'>
                <Text size='xsmall' bold>
                  Status
              </Text>
              </div>
            </Row>
          </li>
          {
            history.map(
              ({ timestamp, status, type, equityToken, price, amount, id }) => (
                <li className='token-element-no-avatar' onClick={() => this.props.navigate(id)}>
                  <Row style={{ alignItems: 'center', flexWrap: 'no-wrap' }}>
                    <div className='col'>
                      <Text size='xsmall'>
                        {moment(timestamp).calendar()}
                      </Text>
                    </div>
                    <div className='col'>
                      <Text size='xsmall'>
                        {equityToken}
                      </Text>
                    </div>
                    <div className='col'>
                      <Text size='xsmall'>
                        {type}
                      </Text>
                    </div>
                    <div className='col'>
                      <Text size='xsmall'>
                        {amount}
                      </Text>
                    </div>
                    <div className='col'>
                      <Text size='xsmall'>
                        {price}
                      </Text>
                    </div>
                    <div className='col only-desktop'>
                      <Text size='xsmall'>
                        {status}
                      </Text>
                    </div>
                  </Row>
                </li>
              )
            )
          }
        </ul>
      </div>
    );
  }
}