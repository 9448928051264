import React from 'react';
import './styles.scss';
import { Progress } from 'shards-react';
import { SDGButtons } from '../SDGButtons/SDGButtons';
import { getImage } from '../../utils';
import { Button } from 'react-bootstrap';
import { Text } from 'components/Text';

export const EarthImageCard = ({
  badgeLabel, projectName, description,
  location, investors, daysLeft, funded, avatar, backgroundImage,
  navigate, raisedPercentage, raised, sdgs
}) => (
    <div className='mb-4 col-xxl-4 col-xl-4 col-lg-6 col-sm-12 col-md-12'>
      <div className="EarthImageCard h-100">
        <div className="EarthImageCard__image" style={{ backgroundImage: getImage(backgroundImage) }}>
          <div className="EarthImageCard__image_banner">{badgeLabel}</div>
        </div>

        <div className="EarthImageCard__body">
          <div className="special-box">
            <p className="title">{projectName}</p>
            <p className="box" style={{ backgroundImage: getImage(avatar) }} />
          </div>

          <p className="description">
            {description}
          </p>

          <div className="EarthImageCard__amounts">
            <div className="row">
              <div className="col-5 my-auto">
                <p className="dividend-info">{location.toUpperCase()}</p>
              </div>
            </div>
            <div className="EarthImageCard__column text-center">
              <div className="column-1">
                <p className="header">RAISED</p>
                <p className="description">{raised}</p>
              </div>
              <div className="column-3">
                <p className="header">INVESTORS</p>
                <p className="description">{investors}</p>
              </div>
            </div>
            <Progress value={raisedPercentage} />
            <Text bold din size='small' center>FUNDING</Text>
            <Button className="w-100 mt-2" onClick={navigate}>
              INVEST NOW
            </Button>
          </div>
        </div>
      </div>
    </div>
  );