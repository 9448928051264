import React from 'react';
import { mergeStyles } from 'utils';

const fluidStyle = {
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1
};

const middleStyle = { justifyContent: 'center' };
const centerStyle = { alignItems: 'center' };

export const Box = ({ fluid, center, middle, children, background, ...rest }) => (
  <div
    {...rest}
    style={mergeStyles([
      fluid && fluidStyle,
      center && centerStyle,
      middle && middleStyle,
      background && { backgroundColor: 'white' },
      rest.style
    ])}
  >
    {children}
  </div>
)