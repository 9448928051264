import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EarthCard } from '../EarthCard/EarthCard';
import './styles.scss';
import { EarthText } from '../EarthText/EarthText';
import cn from 'classnames';

class Balance extends Component {
  render() {
    const { balances, className, children, currency } = this.props;

    return (
      <EarthCard className={className}>
        <div className="row mb-2 h-100" style={{ justifyContent: 'space-between' }}>
          {
            balances.map((balance,idx) => {
              return (
                <div key={idx} className='col-4 my-auto' style={{ textAlign: 'center' }}>
                  <EarthText size="large" fontStyle="bold">
                    {balance.label}
                  </EarthText>
                  <EarthText className={cn(balance.color)} size="large" fontStyle="light">
                    {balance.amount} {!balance.noSuffix && currency.toUpperCase()}
                  </EarthText>
                </div>
              )
            })
          }
        </div>
        {children}
      </EarthCard>
    );
  }
};


Balance.defaultProps = {
  balances: [],
  className: null,
};

Balance.propTypes = {
  balances: PropTypes.array,
  className: PropTypes.string,
};


const mapStatetoProps = state => {
  return { currency: state.root.user.currency }
}

export const EarthBalance = connect(mapStatetoProps)(Balance);