import { combineReducers } from 'redux';

const initialState = {
  menuVisible: false,
  locked: false,
  user: {},
  loading: true,
  totalBalance: 0,
  savingsBalance: 0,
  lastInterest: 0,
  choosenFiat: 'usd',
  wallets: [],
  currencyModal: false,
  activity: [],
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_TOTAL_BALANCE':
      return { ...state, totalBalance: action.payload };
    case 'UPDATE_USER':
      return { ...state, user: action.payload };
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    case 'SET_CURRENCY_MODAL':
      return { ...state, currencyModal: action.payload };
    case 'UPDATE_SAVINGS_BALANCE':
      return { ...state, savingsBalance: action.payload };
    case 'UPDATE_LAST_INTEREST':
      return { ...state, lastIntereset: action.payload };
    case 'UPDATE_ACTIVITY':
      return { ...state, activity: action.payload };
    case 'UPDATE_CHOOSEN_FIAT':
      return { ...state, choosenFiat: action.payload };
    case 'UPDATE_BACKUP':
      return { ...state, isBackupDone: action.payload };
    case 'SET_LOCK':
      return { ...state, locked: action.payload };
    default:
      return state;
  }
};

export default combineReducers({ root: rootReducer });
