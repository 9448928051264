import React from 'react'
import { Row, Col } from 'reactstrap';
import { Text } from 'components';
import numeral from 'numeral';

export const Stats = ({ values }) => (
  <Row className='justify-content-center'>
    {
      values.map(
        ({ label, value, icon = {}, type = 'money' }, index) => (
          <Col
            key={index}
            className='highlighteable flex-grow-1'
            style={{ padding: 2 }}
          >
            <div style={{ backgroundColor: 'white', padding: 20 }}>
              <Text din dark size='large'
              >
                {
                  type === 'money' ?
                    `$${numeral(value).format('0.0a')}`
                    :
                    `${numeral(value).format('0.0%')}`
                }
              </Text>
              <div style={{ height: 20, display: 'flex', alignItems: 'center' }}>
                <Text dark style={{ marginTop: -12 }}>{icon.label || label}</Text>
                {icon.image && <img
                  src={icon.image}
                  style={{ marginTop: -12, marginLeft: 5, borderRadius: '50%', width: 20, height: 20 }}
                />}
              </div>
            </div>
          </Col>
        )
      )
    }
  </Row>
)