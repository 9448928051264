import React from 'react';

export const Container = ({ children, style, box, ...rest }) => (
  <div
    {...rest}
    className={`container ${box ? 'box-section' : ''}`}
    style={{ height: '100%', ...style }}
  >
    {children}
  </div>
)